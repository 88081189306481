import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Drawer, Grid, Typography, LinearProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import ListItem from "@material-ui/core/ListItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WarningIcon from "@material-ui/icons/Warning";
import { connect } from "react-redux";
import "./Sidebar.css";

import { setAlertsAction } from "../../../../actions/alertactions";

import { ModulesMenu, TechnologiesMenu } from "../../../../components";
import { AddPopOver } from "../../../../components";
import { moduleServices } from "../../../../services/modulesservices";
import { technologiesServices } from "../../../../services/technologiesservices";
import {
  setTechnologiesAction,
  getTechnologiesAsyncAction,
  getTechnologiesRequestAction,
  setSelectedTechnologyAction
} from "../../../../actions/technologyactions";

import {
  setModulesAction,
  getModulesAsyncAction,
  getModulesRequestAction,
  setSelectedModuleAction
} from "../../../../actions/moduleactions";

import { setSelectedResourceAction } from "../../../../actions/resourceactions";

import { setExpandedPanelAction } from "../../../../actions/sidebaractions";

const ExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "0px",
      backgroundColor: "#F2FAFF"
    }
  },
  expanded: { backgroundColor: "#F2FAFF" }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    marginBottom: -1,
    minHeight: 0,
    "&$expanded": {
      minHeight: 0,
      backgroundColor: "#F2FAFF"
    }
  },
  content: {
    backgroundColor: "#F4F4F4",
    padding: "8px 10px 10px 50px",
    margin: "0px",
    "&$expanded": {
      padding: "8px 10px 10px 50px",
      margin: "0px"
    }
  },
  expanded: { backgroundColor: "#F2FAFF" }
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: { padding: 0 }
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 300,
    marginTop: "187px",
    [theme.breakpoints.down("lg")]: {
      height: "calc(100% - 187px)"
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column"
  },
  itemtextselected: {
    borderBottom: "2px solid #F3572F",
    paddingRight: "auto",
    paddingBottom: "18px",
    transitionDuration: ".2s",
    width: "90%"
  },
  itemtext: {
    transitionDuration: ".2s",
    paddingRight: "auto",
    width: "90%"
  }
}));

function Sidebar(props) {
  const {
    open,
    variant,
    onClose,
    className,
    match,
    getModulesDispatch,
    getTechnologiesDispatch,
    setSelectedModuleDispatch,
    setSelectedTechnologyDispatch,
    selectedTechnology,
    selectedModule,
    setSelectedResourceDispatch,
    selectedResource,
    setExpandedPanelDispatch,
    expandedPanel,
    setModulesDispatch,
    getTechnologiesRequestDispatch,
    getModulesRequestDispatch,
    setTechnologiesDispatch,
    setAlertsDispatch,
    modulesInfo,
    technologiesInfo,
    ...rest
  } = props;

  const classes = useStyles();
  useEffect(() => {
    props.getTechnologiesDispatch(match.params.pid);
    props.getModulesDispatch({ pid: match.params.pid, state: "" });
  }, []);

  const addModule = formData => {
    getModulesRequestDispatch();
    setExpandedPanelDispatch("panel1");
    moduleServices.addModule(formData, match.params.pid).then(
      data => props.setModulesDispatch(data),
      error =>
        props.setAlertsDispatch({
          message: "Failed to add module",
          title: "Alert",
          open: true
        })
    );
  };

  const addTechnology = formData => {
    getTechnologiesRequestDispatch();
    setExpandedPanelDispatch("panel2");
    technologiesServices.addTechnology(formData, match.params.pid).then(
      data => props.setTechnologiesDispatch(data),
      error =>
        props.setAlertsDispatch({
          message: "Failed to add technology",
          title: "Alert",
          open: true
        })
    );
  };

  const handleChange = panel => (event, newExpanded) => {
    event.stopPropagation();
    if (!newExpanded) {
      return;
    } else {
      setExpandedPanelDispatch(panel);
    }
  };

  useEffect(() => {
    getTechnologiesDispatch(match.params.pid);
    getModulesDispatch({ pid: match.params.pid, state: "" });
  }, []);

  return (
    <Drawer
      anchor={variant === "temporary" ? "right" : "left"}
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <ExpansionPanel
          square
          expanded={expandedPanel === "panel1"}
          onChange={handleChange("panel1")}
        >
          <ExpansionPanelSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Grid container justify="space-between">
              <Grid item>
                <Typography>MODULES</Typography>
              </Grid>
              <Grid item>
                <AddPopOver
                  buttonText="+"
                  title="Add Module"
                  handleSubmit={addModule}
                  style={{ padding: "1px 8px", margin: 0 }}
                />
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              {modulesInfo.loading ? (
                <LinearProgress />
              ) : modulesInfo.error ? (
                <Alert severity="error">
                  Something went wrong while loading modules!
                </Alert>
              ) : (
                props.modules.map(item => (
                  <ListItem
                    key={`plat-${item.Mid}`}
                    className="modulelistitem"
                    onClick={() => setSelectedModuleDispatch(item.Mid)}
                  >
                    <Grid container>
                      <Grid item>
                        <ModulesMenu
                          pid={match.params.pid}
                          item={item}
                          buttonText={
                            <MoreVertIcon
                              style={{
                                color: "#969696",
                                fontSize: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        className={
                          selectedModule === item.Mid
                            ? classes.itemtextselected
                            : classes.itemtext
                        }
                      >
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <Typography variant="h6">{item.Mname}</Typography>
                          </Grid>
                          {item.Alerts !== 0 && [
                            <Grid item xs={1} key={`plat-${item.Mid}`}>
                              <WarningIcon
                                style={{
                                  fontSize: "20px",
                                  verticalAlign: "middle",
                                  color: "#F3572F"
                                }}
                              />
                            </Grid>,
                            <Grid item xs={1} key={`plat-${item.Mid}+1`}>
                              <Typography
                                variant="h6"
                                style={{ color: "#F3572F" }}
                              >
                                {item.Alerts}
                              </Typography>
                            </Grid>
                          ]}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expandedPanel === "panel2"}
          onChange={handleChange("panel2")}
        >
          <ExpansionPanelSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Grid container justify="space-between">
              <Grid item>
                <Typography>TECHNOLOGIES</Typography>
              </Grid>
              <Grid item>
                <AddPopOver
                  buttonText="+"
                  title="Add technology"
                  handleSubmit={addTechnology}
                  style={{ padding: "1px 8px", margin: 0 }}
                />
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              {technologiesInfo.loading ? (
                <LinearProgress />
              ) : technologiesInfo.error ? (
                <Alert severity="error">
                  Something went wrong while loading technologies!
                </Alert>
              ) : (
                props.technologies.map(item => (
                  <ListItem
                    key={`tec-${item.Tid}`}
                    className="modulelistitem"
                    onClick={() => setSelectedTechnologyDispatch(item.Tid)}
                  >
                    <Grid container>
                      <Grid item>
                        <TechnologiesMenu
                          pid={match.params.pid}
                          item={item}
                          buttonText={
                            <MoreVertIcon
                              style={{
                                color: "#969696",
                                fontSize: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        className={
                          selectedTechnology === item.Tid
                            ? classes.itemtextselected
                            : classes.itemtext
                        }
                      >
                        <Typography variant="h6">{item.Tname}</Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expandedPanel === "panel3"}
          onChange={handleChange("panel3")}
        >
          <ExpansionPanelSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Grid container justify="space-between">
              <Grid item>
                <Typography>RESOURCES</Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              <ListItem key={1} className="modulelistitem">
                <Grid container>
                  <Grid item></Grid>
                  <Grid
                    item
                    className={
                      selectedResource === "members"
                        ? classes.itemtextselected
                        : classes.itemtext
                    }
                  >
                    <Typography variant="h6">
                      &nbsp;&nbsp;&nbsp;Team members
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </Drawer>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getModulesDispatch: e => dispatch(getModulesAsyncAction(e)),
    getTechnologiesDispatch: e => dispatch(getTechnologiesAsyncAction(e)),
    setModulesDispatch: e => dispatch(setModulesAction(e)),
    getModulesRequestDispatch: () => dispatch(getModulesRequestAction()),
    setTechnologiesDispatch: e => dispatch(setTechnologiesAction(e)),
    getTechnologiesRequestDispatch: () =>
      dispatch(getTechnologiesRequestAction()),
    setExpandedPanelDispatch: e => dispatch(setExpandedPanelAction(e)),
    setAlertsDispatch: e => dispatch(setAlertsAction(e)),
    setSelectedModuleDispatch: e => dispatch(setSelectedModuleAction(e)),
    setSelectedTechnologyDispatch: e =>
      dispatch(setSelectedTechnologyAction(e)),
    setSelectedResourceDispatch: e => dispatch(setSelectedResourceAction(e))
  };
};

const mapStateToProps = state => {
  return {
    expandedPanel: state.sidebarReducer.expandedPanel,
    modules: state.moduleReducer.modules,
    technologies: state.technologyReducer.technologies,
    selectedModule: state.moduleReducer.selectedModule,
    selectedTechnology: state.technologyReducer.selectedTechnology,
    selectedResource: state.resourceReducer.selectedResource,
    modulesInfo: {
      loading: state.moduleReducer.loading,
      error: state.moduleReducer.error
    },
    technologiesInfo: {
      loading: state.technologyReducer.loading,
      error: state.technologyReducer.error
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
