import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
  Avatar,
  Switch,
  Grid,
  withStyles
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";

import history from "../../../../helpers/history";

import "./menubar.css";

const jwt = require("jsonwebtoken");

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    backgroundColor: "white"
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  toolbar: {
    minHeight: "96px"
  },
  usericon: {
    padding: "10px"
  },
  signout: {
    color: "black",
    height: "36px",
    borderRadius: "25px",
    "&:hover": {
      backgroundColor: "#E85555",
      color: "white"
    }
  },
  switch: {
    switchBase: {
      color: "red",
      "&$checked": {
        color: "red"
      },
      "&$checked + $track": {
        backgroundColor: "red"
      }
    },
    checked: {},
    track: {}
  }
}));

const CustomSwitch = withStyles({
  switchBase: {
    color: "#e85555",
    "&$checked": {
      color: "#e85555"
    },
    "&$checked + $track": {
      backgroundColor: "#e85555"
    }
  },
  checked: {},
  track: {}
})(Switch);

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(true);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const toggleChecked = () => {
    sessionStorage.setItem("user-status", checked ? "user" : "admin");
    setChecked(prev => !prev);
    if (props.props.match.params.pid) {
      !checked
        ? history.push(
            `/project/admin/${props.props.match.params.pid}/${props.props.match.params.name}`
          )
        : history.push(
            `/project/user/${props.props.match.params.pid}/${props.props.match.params.name}`
          );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    if (!window.confirm("Are you sure you want to logout?")) {
      return;
    }
    localStorage.removeItem("user");
    localStorage.removeItem("user-google");
    history.push("/sign-in");
  };

  const user = jwt.decode(localStorage.getItem("user-google"));
  const userAdminStatus = jwt.decode(localStorage.getItem("user")).isadmin;

  useEffect(() => {
    if (!userAdminStatus) {
      return;
    }
    if (sessionStorage.getItem("user-status")) {
      setChecked(sessionStorage.getItem("user-status") === "admin");
    } else {
      sessionStorage.setItem("user-status", "admin");
    }
  }, []);

  return (
    <AppBar {...rest} height="400px" className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <a href="/home">
          <img alt="Logo" src="/images/logo.png" height="72px" />
        </a>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <div>
            <Grid container direction="column">
              <Grid item>
                <Typography>{user && user.name}</Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Typography variant="body2">User</Typography>
                  {userAdminStatus && [
                    <CustomSwitch
                      name="adminswitch"
                      size="small"
                      checked={checked}
                      onChange={toggleChecked}
                    />,
                    <Typography variant="body2">Admin</Typography>
                  ]}
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div className={classes.usericon}>
            <Avatar alt="User" src={user && user.picture} />
          </div>
          <Button
            onClick={signOut}
            variant="outlined"
            className={classes.signout}
          >
            LOG OUT
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            onClick={handleClick}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={signOut}>
              Logout &nbsp;
              <InputIcon />
            </MenuItem>
          </Menu>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
