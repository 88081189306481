import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { forwardRef } from "react";

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import { IconButton } from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Avatar from "react-avatar";

import { connect } from "react-redux";

import { getProjectUsersAsyncAction } from "../../../../actions/resourceactions";
import { getModulesAsyncAction } from "../../../../actions/moduleactions";
import { setAlertsAction } from "../../../../actions/alertactions";

import { resourceServices } from "../../../../services/resourceservices";
import { AddUserPopup } from "./components";

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <PersonAddIcon style={{ color: "black" }} {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => (
    <Check {...props} ref={ref} style={{ color: "black" }} />
  )),
  Clear: forwardRef((props, ref) => (
    <Clear {...props} ref={ref} style={{ color: "black" }} />
  )),
  Delete: forwardRef((props, ref) => (
    <Delete {...props} ref={ref} style={{ color: "black" }} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} style={{ color: "black" }} />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit {...props} ref={ref} style={{ color: "black" }} />
  )),
  Export: forwardRef((props, ref) => (
    <SaveAlt {...props} ref={ref} style={{ color: "black" }} />
  )),
  Filter: forwardRef((props, ref) => (
    <FilterList {...props} ref={ref} style={{ color: "black" }} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} style={{ color: "black" }} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage {...props} ref={ref} style={{ color: "black" }} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} style={{ color: "black" }} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} style={{ color: "black" }} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} ref={ref} style={{ color: "black" }} />
  )),
  Search: forwardRef((props, ref) => (
    <Search {...props} ref={ref} style={{ color: "black" }} />
  )),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDropUpIcon {...props} ref={ref} style={{ color: "black" }} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} style={{ color: "black" }} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref} style={{ color: "black" }} />
  )),
  RemoveUser: forwardRef((props, ref) => (
    <RemoveCircleIcon {...props} ref={ref} style={{ color: "black" }} />
  )),
  RelieveUser: forwardRef((props, ref) => (
    <ExitToAppIcon {...props} ref={ref} style={{ color: "black" }} />
  ))
};

function UsersList(props) {
  useEffect(() => {
    props.getProjectUsersDispatch(props.pid);
  }, []);

  useEffect(() => {
    setState({ columns: [...state.columns], data: props.projectUsers });
  }, [props.projectUsers]);

  const [state, setState] = React.useState({
    columns: [
      {
        title: "Avatar",
        field: "Image",
        render: rowData => (
          <Avatar
            src={rowData.Image}
            size="35px"
            round="35px"
            name={rowData.Name}
            maxInitials={2}
            style={{ width: 40, borderRadius: "50%" }}
          />
        ),
        editComponent: props => (
          <IconButton variant="contained" component="label">
            <AccountCircleIcon />
            <input
              type="file"
              style={{ display: "none" }}
              onChange={e => props.onChange(e.target.files[0])}
            />
          </IconButton>
        )
      },
      {
        title: "Eid",
        field: "Eid",
        hidden: true
      },
      {
        title: "Status",
        field: "Status",
        hidden: true
      },
      {
        title: "Name",
        field: "Name"
      },
      {
        title: "Email",
        field: "Email",
        editable: "onAdd"
      },
      {
        title: "Admin Status",
        field: "Isadmin",
        lookup: { true: "true", false: "false" }
      }
    ],
    data: []
  });

  return (
    <div
      style={{
        height: "calc(100vh - 187px)",
        overflowY: "auto",
        overflowX: "auto"
      }}
    >
      <MaterialTable
        style={{ color: "black" }}
        icons={tableIcons}
        title="USERS IN THIS PROJECT"
        options={{
          rowStyle: rowData => ({
            backgroundColor: 2 === rowData.Status ? "antiquewhite" : "#FFF"
          }),
          headerStyle: { color: "black" },
          pageSize: 5,
          pageSizeOptions: [5, 10, 20],
          doubleHorizontalScroll: true,
          actionsColumnIndex: -1
        }}
        actions={[
          rowData =>
            rowData.Status === 1
              ? {
                  icon: tableIcons.RelieveUser,
                  tooltip: "Relieve user",
                  onClick: (event, rowData) => {
                    if (
                      !window.confirm(
                        "You sure you want to relieve the user " +
                          rowData.Name +
                          "?"
                      )
                    ) {
                      return;
                    }
                    resourceServices.relieveUser(rowData.Eid).then(
                      data => {
                        props.getProjectUsersDispatch(props.pid);
                        props.getModulesDispatch({ pid: props.pid, state: "" });
                      },
                      err =>
                        props.setAlertsDispatch({
                          message: "Failed to relieve user",
                          title: "Alert",
                          open: true
                        })
                    );
                  }
                }
              : {
                  hidden: true
                },
          {
            icon: tableIcons.RemoveUser,
            tooltip: "Remove user from project",
            onClick: (event, rowData) => {
              if (
                !window.confirm(
                  "You sure you want to remove " +
                    rowData.Name +
                    " from the project?"
                )
              ) {
                return;
              }
              resourceServices
                .removeUserFromProject(rowData.Eid, props.pid)
                .then(
                  data => {
                    props.getProjectUsersDispatch(props.pid);
                    props.getModulesDispatch({ pid: props.pid, state: "" });
                  },
                  err =>
                    props.setAlertsDispatch({
                      message: "Failed to remove user from project",
                      title: "Alert",
                      open: true
                    })
                );
            }
          },
          {
            icon: () => (
              <AddUserPopup pid={props.pid} buttonText={<tableIcons.Add />} />
            ),
            tooltip: "Add a new user",
            isFreeAction: true
          }
        ]}
        columns={state.columns}
        data={state.data}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              if (!newData.Name && newData.Name[0] === " ") {
                props.setAlertsDispatch({
                  message: "Please enter a valid name",
                  title: "Alert",
                  open: true
                });
                resolve();
                return;
              }
              var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
              if (!newData.Email || !newData.Email.match(mailformat)) {
                props.setAlertsDispatch({
                  message: "Please enter a valid email",
                  title: "Alert",
                  open: true
                });
                resolve();
                return;
              }
              const file = newData.Image;
              const reader = new FileReader();
              reader.addEventListener(
                "load",
                function() {
                  resourceServices
                    .editUser(
                      newData.Eid,
                      newData.Name,
                      newData.Email,
                      newData.Isadmin === "false" || !newData.Isadmin
                        ? false
                        : true,
                      reader.result
                    )
                    .then(
                      data => {
                        resolve();
                        props.getProjectUsersDispatch(props.pid);
                        props.getModulesDispatch({ pid: props.pid, state: "" });
                      },
                      err => {
                        resolve();
                        props.setAlertsDispatch({
                          message: "Failed to edit user",
                          title: "Alert",
                          open: true
                        });
                      }
                    );
                },
                false
              );

              if (file !== oldData.Image) {
                reader.readAsDataURL(file);
              } else {
                resourceServices
                  .editUser(
                    newData.Eid,
                    newData.Name,
                    newData.Email,
                    newData.Isadmin === "false" || !newData.Isadmin
                      ? false
                      : true,
                    newData.Image
                  )
                  .then(
                    data => {
                      resolve();
                      props.getProjectUsersDispatch(props.pid);
                      props.getModulesDispatch({ pid: props.pid, state: "" });
                    },
                    err => {
                      resolve();
                      props.setAlertsDispatch({
                        message: "Failed to edit user",
                        title: "Alert",
                        open: true
                      });
                    }
                  );
              }
              resolve();
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              resourceServices.deleteUser(oldData.Eid).then(
                data => {
                  resolve();
                  props.getProjectUsersDispatch(props.pid);
                  props.getModulesDispatch({ pid: props.pid, state: "" });
                },
                err => {
                  resolve();
                  props.setAlertsDispatch({
                    message: "Failed to delete user",
                    title: "Alert",
                    open: true
                  });
                }
              );
              resolve();
            })
        }}
      />
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getProjectUsersDispatch: e => dispatch(getProjectUsersAsyncAction(e)),
    setAlertsDispatch: e => dispatch(setAlertsAction(e)),
    getModulesDispatch: e => dispatch(getModulesAsyncAction(e))
  };
};

const mapStateToProps = state => {
  return {
    projectUsers: state.resourceReducer.users
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
