import { takeLatest, put } from "redux-saga/effects";
import { projectServices } from "../services/projectservices";
import { moduleServices } from "../services/modulesservices";
import { componentServices } from "../services/componentsservices";
import { technologiesServices } from "../services/technologiesservices";

import {
  getProjectsRequestAction,
  setProjectsAction,
  getProjectsFailureAction
} from "../actions/projectactions";

import {
  getModulesRequestAction,
  setModulesAction,
  getModulesFailureAction
} from "../actions/moduleactions";

import {
  getComponentsRequestAction,
  setComponentsAction,
  getComponentsFailureAction
} from "../actions/componentactions";

import {
  getTechnologiesRequestAction,
  setTechnologiesAction,
  getTechnologiesFailureAction
} from "../actions/technologyactions.js";

import {
  getProjectUsersRequestAction,
  setProjectUsersAction,
  getProjectUsersFailureAction
} from "../actions/resourceactions.js";

import {
  projectConstants,
  moduleConstants,
  technologyConstants,
  resourceConstants
} from "../constants/constants";

function* getProjectsApiCallAsync() {
  yield put(getProjectsRequestAction());
  try {
    const data = yield projectServices.getProjects();
    yield put(setProjectsAction(data));
  } catch (err) {
    yield put(getProjectsFailureAction());
  }
}
function* getModulesApiCallAsync(action) {
  if (action.data.state !== "update") {
    yield put(getModulesRequestAction());
  }
  try {
    const data = yield moduleServices.getModules(action.data.pid);
    yield put(setModulesAction(data));
  } catch (err) {
    yield put(getModulesFailureAction());
  }
}

function* getModuleComponentsApiCallAsync(action) {
  if (action.state !== "update") {
    yield put(getComponentsRequestAction());
  }
  try {
    const data = yield componentServices.getModuleComponents(action.data);
    yield put(setComponentsAction({ data: data, state: action.data.state }));
  } catch (err) {
    yield put(getComponentsFailureAction());
  }
}

function* getTechnologiesApiCallAsync(action) {
  yield put(getTechnologiesRequestAction());
  try {
    const data = yield technologiesServices.getTechnologies(action.data);
    yield put(setTechnologiesAction(data));
  } catch (err) {
    yield put(getTechnologiesFailureAction());
  }
}

function* getTechnologyComponentsApiCallAsync(action) {
  yield put(getComponentsRequestAction());
  try {
    const data = yield componentServices.getTechnologyComponents(action.data);
    yield put(setComponentsAction({ data: data, state: action.data.state }));
  } catch (err) {
    yield put(getComponentsFailureAction());
  }
}

function* getProjectUserApiCallAsync(action) {
  yield put(getProjectUsersRequestAction());
  try {
    const data = yield projectServices.getProjectUsers(action.data);
    yield put(setProjectUsersAction(data));
  } catch (err) {
    yield put(getProjectUsersFailureAction());
  }
}

export function* watchApiCall() {
  yield takeLatest(projectConstants.FETCH_PROJECTS, getProjectsApiCallAsync);
  yield takeLatest(moduleConstants.FETCH_MODULES, getModulesApiCallAsync);
  yield takeLatest(
    moduleConstants.FETCH_MODULE_COMPONENTS,
    getModuleComponentsApiCallAsync
  );
  yield takeLatest(
    technologyConstants.FETCH_TECHNOLOGIES,
    getTechnologiesApiCallAsync
  );
  yield takeLatest(
    technologyConstants.FETCH_TECHNOLOGY_COMPONENTS,
    getTechnologyComponentsApiCallAsync
  );
  yield takeLatest(
    resourceConstants.FETCH_PROJECT_USERS,
    getProjectUserApiCallAsync
  );
}
