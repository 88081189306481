import React from "react";
import { Button } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";
import { EditPopOver } from "./components";
import { ConfirmWindow } from "../../../../../../components";

function ProjectMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { project, buttonText, deleteProject } = props;

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ minWidth: 0 }}
      >
        {buttonText}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem>
          <EditPopOver
            buttonText={<EditIcon />}
            formData={{
              name: project.Pname,
              minUsers: project.Minusers,
              pid: project.Pid
            }}
          />
        </ListItem>
        <ListItem onClick={handleClose}>
          <ConfirmWindow
            buttonText={<DeleteIcon />}
            handleSubmit={deleteProject}
            message="You want to delete the project"
          />
        </ListItem>
      </Menu>
    </div>
  );
}

export default ProjectMenu;
