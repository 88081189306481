import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

import { Topbar } from "./components";
import { Alert } from "../../components";
import { setAlertsAction } from "../../actions/alertactions";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 96,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 96
    }
  },
  content: {
    height: "90%",
    paddingTop: "10px"
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <Topbar props={props.children.props} />
      <Alert alert={props.alert} handleClose={props.setAlertDispatch} />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

const mapDispatchToProps = dispatch => {
  return {
    setAlertDispatch: () =>
      dispatch(setAlertsAction({ message: "", open: false, title: "" }))
  };
};

const mapStateToProps = state => {
  return {
    alert: state.alertReducer.alert
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
