import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";

import { AddPopOver } from "../../components";
import { moduleServices } from "../../services/modulesservices";
import {
  setModulesAction,
  getModulesRequestAction
} from "../../actions/moduleactions";
import { setAlertsAction } from "../../actions/alertactions";

function AddModulePopOver(props) {
  const handleSubmits = formData => {
    props.getModulesRequestDispatch();
    moduleServices.addModule(formData, props.pid).then(
      data => props.setModulesDispatch(data),
      error =>
        props.setAlertsDispatch({
          message: "Failed to add module",
          title: "Alert",
          open: true
        })
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Typography variant="h4">
            No modules in this project. You want to add some?
          </Typography>
        </Grid>
        <br />
        <Grid item>
          <AddPopOver
            buttonText="Add Module"
            title="Add Module"
            handleSubmit={handleSubmits}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setModulesDispatch: e => dispatch(setModulesAction(e)),
    getModulesRequestDispatch: () => dispatch(getModulesRequestAction()),
    setAlertsDispatch: e => dispatch(setAlertsAction(e))
  };
};

export default connect(null, mapDispatchToProps)(AddModulePopOver);
