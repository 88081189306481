import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AddPopOver } from "../../components";
import { connect } from "react-redux";

import { technologiesServices } from "../../services/technologiesservices";
import { getTechnologyComponentsAsyncAction } from "../../actions/technologyactions";
import { setAlertsAction } from "../../actions/alertactions";

function AddTechnologyComponentPopOver(props) {
  const handleSubmits = formData => {
    technologiesServices
      .addTechnologyComponent(formData, props.tid, props.pid)
      .then(
        data =>
          props.getTechnologyComponentsDispatch({
            tid: props.tid,
            filter: { resource: "", expertise: "" },
            state: "update"
          }),
        error =>
          props.setAlertsDispatch({
            message: "Failed to add technology Component",
            title: "Alert",
            open: true
          })
      );
  };
  return (
    <div style={{ height: "100%" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Typography variant="h4">
            No Components present. You want to add some?
          </Typography>
        </Grid>
        <br />
        <Grid item>
          <AddPopOver
            buttonText="Add Component"
            title="Add Component"
            handleSubmit={handleSubmits}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getTechnologyComponentsDispatch: e =>
      dispatch(getTechnologyComponentsAsyncAction(e)),
    setAlertsDispatch: e => dispatch(setAlertsAction(e))
  };
};

export default connect(null, mapDispatchToProps)(AddTechnologyComponentPopOver);
