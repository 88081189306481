import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AddPopOver } from "../../components";
import { connect } from "react-redux";

import { moduleServices } from "../../services/modulesservices";
import {
  getModuleComponentsAsyncAction,
  getModulesAsyncAction
} from "../../actions/moduleactions";
import { setAlertsAction } from "../../actions/alertactions";

function AddModuleComponentPopOver(props) {
  const handleSubmits = formData => {
    moduleServices.addModuleComponent(formData, props.mid, props.pid).then(
      data => {
        props.getModuleComponentsDispatch({
          mid: props.mid,
          filter: { resource: "", expertise: "" },
          state: "update"
        });
        props.getModulesDispatch({ pid: props.pid, state: "" });
      },
      error =>
        props.setAlertsDispatch({
          message: "Failed to add module Component",
          title: "Alert",
          open: true
        })
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Typography variant="h4">
            No Components present. You want to add some?
          </Typography>
        </Grid>
        <br />
        <Grid item>
          <AddPopOver
            buttonText="Add Component"
            title="Add Component"
            handleSubmit={handleSubmits}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getModuleComponentsDispatch: e =>
      dispatch(getModuleComponentsAsyncAction(e)),
    setAlertsDispatch: e => dispatch(setAlertsAction(e)),
    getModulesDispatch: e => dispatch(getModulesAsyncAction(e))
  };
};

export default connect(null, mapDispatchToProps)(AddModuleComponentPopOver);
