import { BASEURL } from "../constants/constants";
import history from "../helpers/history";

export const technologiesServices = {
  deleteTechnology,
  editTechnology,
  addTechnology,
  addTechnologyComponent,
  deleteTechnologyComponent,
  getTechnologies
};

function getTechnologies(pid) {
  return fetch(`${BASEURL}/technologies?pid=${pid}`, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function deleteTechnologyComponent(cid) {
  return fetch(`${BASEURL}/technology/component?cid=${cid}`, {
    method: "DELETE",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function deleteTechnology(tid, pid) {
  return fetch(`${BASEURL}/technology?tid=${tid}&pid=${pid}`, {
    method: "DELETE",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function editTechnology(tname, tid, pid) {
  return fetch(`${BASEURL}/technology`, {
    method: "PUT",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      tname: tname,
      tid: parseInt(tid),
      pid: parseInt(pid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function addTechnology(tname, pid) {
  return fetch(`${BASEURL}/technology`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      tname: tname,
      pid: parseInt(pid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function addTechnologyComponent(cname, tid, pid) {
  return fetch(`${BASEURL}/technology/component`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cname: cname,
      tid: parseInt(tid),
      pid: parseInt(pid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function handleResponse(response) {
  return response.json().then(text => {
    let data = text;
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("user");
        history.push("/sign-in");
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
