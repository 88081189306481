import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Avatar from "react-avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { connect } from "react-redux";

import {
  Button,
  Divider,
  IconButton,
  Typography,
  Grid
} from "@material-ui/core";

import { getProjectUsersAsyncAction } from "../../../../../../actions/resourceactions";
import { setAlertsAction } from "../../../../../../actions/alertactions";

import { resourceServices } from "../../../../../../services/resourceservices";

function AddUserPopup(props) {
  const { buttonText } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData({
      name: "",
      email: "",
      admin: "false",
      avatar: ""
    });
    setOpen(false);
  };

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    admin: "false",
    avatar: ""
  });

  const handleChange = event => {
    event.stopPropagation();
    setFormData({ ...formData, admin: event.target.value });
  };

  const handleSubmit = event => {
    event.stopPropagation();
    event.preventDefault();
    if (props.projectUsers.filter(e => e.Email === formData.email).length > 0) {
      handleClose();
      props.setAlertsDispatch({
        message: "User already exists in this project",
        title: "Alert",
        open: true
      });
      return;
    }
    resourceServices
      .addUser(
        formData.name,
        formData.email,
        formData.admin === "false" ? false : true,
        props.pid,
        formData.avatar
      )
      .then(
        data => {
          handleClose();
          props.getProjectUsersDispatch(props.pid);
        },
        err => {
          handleClose();
          props.setAlertsDispatch({
            message: "Failed to add user",
            title: "Alert",
            open: true
          });
        }
      );
  };

  const setAvatar = avatarFile => {
    const file = avatarFile.Image;
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function() {
        setFormData({ ...formData, avatar: reader.result });
      },
      false
    );
    reader.readAsDataURL(avatarFile);
  };
  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        {buttonText}
      </IconButton>
      <Dialog
        onKeyDown={e => e.stopPropagation()}
        onClick={event => {
          event.stopPropagation();
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New User</DialogTitle>
        <DialogContent>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <DialogContentText>
              Enter details of the new user
              <Divider />
            </DialogContentText>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <Grid container alignItems="center" justify="center">
                  <Grid item>
                    <IconButton variant="contained" component="label">
                      <Avatar
                        src={formData.avatar}
                        round="100%"
                        name={formData.name}
                        maxInitials={2}
                        style={{ borderRadius: "50%" }}
                      />
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={e => setAvatar(e.target.files[0])}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <Divider style={{ marginLeft: "50%" }} orientation="vertical" />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Username"
                  type="text"
                  fullWidth
                  onChange={e =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
                <TextField
                  required
                  margin="dense"
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  onChange={e =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
                <br />
                <br />
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={formData.admin}
                    onChange={handleChange}
                  >
                    <Grid container>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Admin"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="User"
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit">save</Button>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getProjectUsersDispatch: e => dispatch(getProjectUsersAsyncAction(e)),
    setAlertsDispatch: e => dispatch(setAlertsAction(e))
  };
};

const mapStateToProps = state => {
  return {
    projectUsers: state.resourceReducer.users
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserPopup);
