import initialState, { componentConstants } from "../constants/constants.js";

const componentReducer = (state = initialState.componentReducer, action) => {
  const newState = { ...state };

  switch (action.type) {
    case componentConstants.FETCH_COMPONENTS_REQUEST: {
      newState.components = [];
      newState.loading = true;
      newState.error = false;
      break;
    }
    case componentConstants.FETCH_COMPONENTS_SUCCESS: {
      newState.components = action.data.data;
      newState.loading = false;
      newState.error = false;
      if (action.data.state !== "update")
        newState.expandedNodes = setAllExpanded(newState.components);
      break;
    }
    case componentConstants.FETCH_COMPONENTS_FAILURE: {
      newState.components = [];
      newState.loading = false;
      newState.error = true;
      break;
    }
    case componentConstants.SET_FILTER: {
      newState.filter = action.data;
      break;
    }
    case componentConstants.SET_EXPANDED_NODES: {
      newState.expandedNodes = action.data;
      break;
    }
    case componentConstants.EXPAND_ALL_NODES: {
      newState.expandedNodes = setAllExpanded(newState.components);
      break;
    }
    case componentConstants.COLLAPSE_ALL_NODES: {
      newState.expandedNodes = [];
      break;
    }
    case componentConstants.RESET_COMPONENTS_REDUCER: {
      return initialState.componentReducer;
      break;
    }
    default: {
    }
  }
  return newState;
};

const setAllExpanded = components => {
  if (!components) return;
  let s = components.map(item => {
    return item.Cid + "";
  });
  components.map(item => {
    s = s.concat(setAllExpanded(item.Subcomponents));
  });
  return s;
};

export default componentReducer;
