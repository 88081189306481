import { BASEURL } from "../constants/constants";
import history from "../helpers/history";

export const resourceServices = {
  addUser,
  editUser,
  removeUserFromProject,
  deleteUser,
  relieveUser
};

function addUser(name, email, isadmin, pid, image) {
  return fetch(`${BASEURL}/user`, {
    method: "POST",

    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      name: name,
      email: email,
      isadmin: isadmin,
      pid: parseInt(pid),
      image: image
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function editUser(eid, name, email, isadmin, image) {
  return fetch(`${BASEURL}/user`, {
    method: "PUT",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      eid: eid,
      name: name,
      email: email,
      isadmin: isadmin,
      image: image
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function removeUserFromProject(eid, pid) {
  return fetch(`${BASEURL}/user/project?eid=${eid}&pid=${pid}`, {
    method: "DELETE",

    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function deleteUser(eid) {
  return fetch(`${BASEURL}/user?eid=${eid}`, {
    method: "DELETE",

    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function relieveUser(eid) {
  return fetch(`${BASEURL}/user/relieve?eid=${eid}`, {
    method: "PUT",

    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function handleResponse(response) {
  return response.json().then(text => {
    let data = text;
    if (!response.ok) {
      if (response.status === 401) {
        history.push("/sign-in");
        localStorage.removeItem("user");
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
