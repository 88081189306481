import { componentConstants } from "../constants/constants.js";

export function setComponentsAction(data) {
  return { type: componentConstants.FETCH_COMPONENTS_SUCCESS, data: data };
}

export function getComponentsFailureAction() {
  return { type: componentConstants.FETCH_COMPONENTS_FAILURE };
}

export function getComponentsRequestAction() {
  return { type: componentConstants.FETCH_COMPONENTS_REQUEST };
}

export function setFilterAction(data) {
  return { type: componentConstants.SET_FILTER, data: data };
}

export function setExpandedNodesAction(data) {
  return { type: componentConstants.SET_EXPANDED_NODES, data: data };
}

export function setAllNodesExpanded() {
  return { type: componentConstants.EXPAND_ALL_NODES };
}

export function setAllNodesCollapsed() {
  return { type: componentConstants.COLLAPSE_ALL_NODES };
}

export function resetComponentsReducerAction() {
  return { type: componentConstants.RESET_COMPONENTS_REDUCER };
}
