import React, { useEffect } from "react";
import { Button, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

function EditPopOver(props) {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({ name: "" });
  const handleClickOpen = event => {
    event.stopPropagation();
    setOpen(true);
  };

  const { handleSubmit, title, buttonText } = props;

  useEffect(() => {
    setFormData({ name: props.formData });
  }, [props.formData]);

  const handleChange = event => {
    event.stopPropagation();
    setFormData({ [event.target.id]: event.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmits = formData => {
    formData.preventDefault();
    formData = formData.target;
    handleSubmit(formData.name.value);
    handleClose();
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{buttonText}</MenuItem>
      <Dialog
        onKeyDown={e => e.stopPropagation()}
        onClick={event => {
          event.stopPropagation();
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <form autoComplete="off" onSubmit={handleSubmits}>
            <div>
              <TextField
                id="name"
                required
                autoFocus
                value={formData.name}
                onChange={handleChange}
                label="Name"
                margin="normal"
              />
            </div>
            <br />
            <br />
            <Button type="submit">Apply</Button>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default EditPopOver;
