import { BASEURL } from "../constants/constants";
import history from "../helpers/history";

export const loginServices = {
  loginCheck
};

function loginCheck(token) {
  return fetch(`${BASEURL}/login`, {
    method: "POST",
    body: JSON.stringify({
      token: token
    }),
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function handleResponse(response) {
  return response.text().then(text => {
    let data = text;
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("user");
        history.push("/sign-in");
      }
      if (response.status === 400) {
        history.push("/notFound");
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
