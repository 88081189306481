import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Card, CardContent, CardMedia } from "@material-ui/core";

import Google from "./components/GoogleButton";
import "./SignIn.css";

const useStyles = makeStyles(theme => ({
  imageContainer: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  signin: {
    height: "50%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      height: "100%"
    }
  },
  card: {
    maxWidth: "500px",
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(17),
    paddingRight: theme.spacing(17),
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
      padding: theme.spacing(3)
    }
  }
}));

const SignIn = () => {
  const classes = useStyles();
  const [loginStatus, setLoginStatus] = useState(true);

  return (
    <div className="signinroot">
      <Grid container className="signingrid">
        <Grid className={classes.signin} item>
          <Card
            className={classes.card}
            style={!loginStatus ? { boxShadow: "0px 0px 10px #F42121" } : {}}
          >
            <CardMedia
              className={classes.media}
              image="/static/images/logo.png"
            />
            <CardContent>
              <div className="signinlogo"></div>
              <Google classes={classes} setLoginStatus={setLoginStatus} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item className={classes.imageContainer}>
          <div className="image"></div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignIn;
