import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, SvgIcon } from "@material-ui/core";
import Avatar from "react-avatar";

import WarningIcon from "@material-ui/icons/Warning";

import AvatarStack from "react-avatar-stack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { TreeItem } from "@material-ui/lab";
import { ComponentsMenu } from "../../../../../../components";

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    "&:focus > $content": {
      backgroundColor: "#F2FAFF",
      boxShadow: "1px 2px #00000029"
    }
  },
  content: {
    paddingLeft: "10px",
    borderBottom: "1px solid #00000029"
  },
  labelText: {
    padding: "24px 10px 10px 0px"
  },
  group: {
    margin: 0,
    backgroundColor: "#F7F7F7",
    labelText: { padding: "14px 10px 10px 0px" }
  },
  middletext: {
    padding: "14px 10px 10px 0px"
  },
  leaftext: {
    padding: "14px 10px 10px 0px",
    color: "#585858"
  },
  label: {
    maxWidth: "100%",
    marginLeft: "10px"
  },
  iconContainer: { display: "none" },
  alertcontent: {
    paddingLeft: "10px",
    boxShadow: "1px 1px #00000029",
    backgroundColor: "#E8555540"
  }
}));

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();

  const { item, height, expandedNodes, ...other } = props;
  const labelTextClass =
    height === 0
      ? classes.labelText
      : height === 1
      ? classes.middletext
      : classes.leaftext;

  return (
    <TreeItem
      label={
        <Grid container alignContent="center">
          <Grid item xs={5}>
            <Grid
              container
              alignItems="center"
              style={{ borderLeft: height !== 0 && "2px solid #585858" }}
            >
              <Grid item xs={10}>
                <Typography variant="h6">
                  <Grid container>
                    {height > 1 &&
                      [...Array(height - 1)].map((e, i) => {
                        return (
                          <Grid
                            key={i}
                            item
                            style={{
                              borderRight: "2px solid #585858",
                              marginLeft: "30px"
                            }}
                          ></Grid>
                        );
                      })}
                    <Grid item className={labelTextClass}>
                      {height !== 0 && <strong>----|</strong>}
                      {props.leaf !== 0 ? (
                        <strong
                          style={{
                            paddingRight: "12px",
                            verticalAlign: "sub"
                          }}
                        >
                          {expandedNodes.includes(item.Cid + "") ? (
                            <MinusSquare />
                          ) : (
                            <PlusSquare />
                          )}
                        </strong>
                      ) : (
                        <strong
                          style={{ paddingRight: "12px", verticalAlign: "sub" }}
                        ></strong>
                      )}
                      {item.Cname}
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              {item.Alerts !== 0 &&
                (props.leaf !== 0 || height === 0) && [
                  <Grid item xs={1} key={1}>
                    <WarningIcon
                      style={{
                        fontSize: "20px",
                        verticalAlign: "middle",
                        color: "#F3572F"
                      }}
                    />
                  </Grid>,
                  <Grid item xs={1} key={2}>
                    <Typography variant="h6" style={{ color: "#F3572F" }}>
                      {item.Alerts}
                    </Typography>
                  </Grid>
                ]}
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              borderLeft: "1px solid  #8080804f",
              padding: "5px 10px 5px 15px"
            }}
          >
            {props.leaf === 0 && (
              <AvatarStack
                numberLeftColor="white"
                numberLeftBackgroundColor="black"
                maxAvatarNumber={6}
                size={10}
              >
                {item.Users.filter(item => item.Level === 3).map(item => (
                  <Avatar
                    key={item.Eid}
                    size="35px"
                    round="35px"
                    src={item.Image}
                    title={item.Name}
                    name={item.Name}
                    maxInitials={2}
                    style={item.Status !== 1 && { filter: "grayscale(100%)" }}
                  />
                ))}
              </AvatarStack>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              borderLeft: "1px solid  #8080804f",
              padding: "5px 10px 5px 15px"
            }}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                {props.leaf === 0 && (
                  <AvatarStack
                    numberLeftColor="white"
                    numberLeftBackgroundColor="black"
                    maxAvatarNumber={6}
                    size={10}
                  >
                    {item.Users.filter(item => item.Level === 2).map(item => (
                      <Avatar
                        key={item.Eid}
                        size="35px"
                        round="35px"
                        src={item.Image}
                        title={item.Name}
                        name={item.Name}
                        maxInitials={2}
                        style={
                          item.Status !== 1 && { filter: "grayscale(100%)" }
                        }
                      />
                    ))}
                  </AvatarStack>
                )}
              </Grid>
              <Grid item>
                <ComponentsMenu
                  pid={props.pid}
                  item={item}
                  id={props.id}
                  buttonText={
                    <MoreVertIcon
                      style={{
                        color: "#969696",
                        fontSize: "20px",
                        verticalAlign: "middle"
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      classes={{
        root: classes.root,
        content:
          height === 0
            ? classes.content
            : item.Alerts === 0 || props.leaf !== 0
            ? classes.content
            : classes.alertcontent,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer
      }}
      {...other}
    />
  );
}
export default StyledTreeItem;
