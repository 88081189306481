import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";

import WarningIcon from "@material-ui/icons/Warning";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { connect } from "react-redux";

import { projectServices } from "../../../../services/projectservices";
import history from "../../../../helpers/history";
import { ProjectMenu, ResourceView } from "./components";
import {
  setProjectsAction,
  getProjectsRequestAction
} from "../../../../actions/projectactions";
import { setAlertsAction } from "../../../../actions/alertactions";

import "./ProjectCard.css";

const jwt = require("jsonwebtoken");

const useStyles = makeStyles(theme => ({
  root: {
    "&:hover": { boxShadow: "5px 5px 5px gray;" }
  },
  content: {
    minHeight: "250px",
    cursor: "pointer"
  },
  contentalert: {
    minHeight: "250px",
    cursor: "pointer",
    borderBottomStyle: "solid",
    borderColor: "#E85555",
    border: "2px"
  }
}));

const ProjectCard = props => {
  const {
    className,
    project,
    setProjectsDispatch,
    setAlertsDispatch,
    getProjectsRequestDispatch,
    ...rest
  } = props;

  const classes = useStyles();

  const deleteProject = () => {
    getProjectsRequestDispatch();
    projectServices.deleteProject(parseInt(project.Pid)).then(
      data => setProjectsDispatch(data),
      err =>
        props.setAlertsDispatch({
          message: "Failed to delete project",
          title: "Alert",
          open: true
        })
    );
  };
  const user = jwt.decode(localStorage.getItem("user"));
  let alert = "none";
  let contentclass = classes.content;
  if (project.Alerts !== 0) {
    alert = "block";
    contentclass = classes.contentalert;
  }

  const cardSelect = () => {
    if (
      localStorage.getItem("user") &&
      !jwt.decode(localStorage.getItem("user")).isadmin
    ) {
      history.push(`/project/user/${project.Pid}/${project.Pname}`);
      return;
    }
    if (sessionStorage.getItem("user-status") === "admin") {
      history.push(`/project/admin/${project.Pid}/${project.Pname}`);
    } else {
      {
        history.push(`/project/user/${project.Pid}/${project.Pname}`);
      }
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={contentclass} onClick={cardSelect}>
        <Grid container justify="space-between">
          <Grid item className="heading">
            <Typography align="left" variant="h3">
              {project.Pname}
            </Typography>
          </Grid>
          <Grid item>
            {user && user.isadmin && (
              <ProjectMenu
                buttonText={<MoreVertIcon />}
                project={project}
                deleteProject={deleteProject}
              />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className="module">
            <Typography align="left" variant="h5">
              MODULES
            </Typography>
            <div className="spacer"></div>
            <Typography align="left" variant="h5" style={{ color: "#F3572F" }}>
              {project.Modcount}
            </Typography>
          </Grid>
          <Grid item className="feature">
            <Typography align="left" variant="h5">
              COMPONENTS
            </Typography>
            <div className="spacer"></div>
            <Typography
              align="left"
              variant="h5"
              style={{ color: "#000" }}
              className="componenttext"
            >
              &nbsp;{project.Compcount}
            </Typography>
          </Grid>
          <Grid item className="alert1" style={{ display: alert }}>
            <Typography align="left" variant="h5">
              ALERTS
            </Typography>
            <div className="spacer"></div>
            <Typography
              align="left"
              variant="h5"
              className="alerttext"
              style={{ color: "white" }}
            >
              <WarningIcon
                style={{
                  fontSize: "20px",
                  verticalAlign: "middle",
                  color: "white",
                  marginLeft: "5px"
                }}
              />
              &nbsp;{project.Alerts}
            </Typography>
          </Grid>
        </Grid>
        <div>
          <br></br>
          <Typography align="left" variant="h5">
            {project.Users.length} MEMBERS
          </Typography>
        </div>
        <div>
          <ResourceView users={project.Users} />
        </div>
      </CardContent>
    </Card>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setProjectsDispatch: e => dispatch(setProjectsAction(e)),
    setAlertsDispatch: e => dispatch(setAlertsAction(e)),
    getProjectsRequestDispatch: () => dispatch(getProjectsRequestAction())
  };
};

export default connect(null, mapDispatchToProps)(ProjectCard);
