import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Button,
  LinearProgress,
  Tooltip
} from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import { connect } from "react-redux";

import { TreeView, Alert } from "@material-ui/lab";
import ReorderIcon from "@material-ui/icons/Reorder";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { StyledTreeItem } from "./components";

import {
  AddModuleComponentPopOver,
  AddTechnologyComponentPopOver
} from "../../../../components";

import { getTechnologyComponentsAsyncAction } from "../../../../actions/technologyactions";

import { getModuleComponentsAsyncAction } from "../../../../actions/moduleactions";

import {
  setExpandedNodesAction,
  setAllNodesCollapsed,
  setAllNodesExpanded
} from "../../../../actions/componentactions.js";
import history from "../../../../helpers/history.js";

const useStyles = makeStyles(theme => ({
  root: {
    ul: {
      margin: 0
    },
    backgroundColor: theme.palette.white
  },
  heading: {
    backgroundColor: "#F4F4F4",
    padding: "10px 10px 10px 50px"
  },
  headingDontknow: {
    backgroundColor: "#F4F4F4",
    padding: "10px 10px 10px 20px"
  }
}));

function Tree(props) {
  return props.components.map((item, i) => (
    <StyledTreeItem
      expandedNodes={props.expandedNodes}
      key={item.Cid}
      pid={props.pid}
      nodeId={item.Cid + ""}
      height={props.height}
      leaf={item.Subcomponents.length}
      item={item}
      id={props.id}
    >
      {item.Subcomponents && (
        <Tree
          pid={props.pid}
          components={item.Subcomponents}
          height={props.height + 1}
          id={props.id}
          expandedNodes={props.expandedNodes}
        />
      )}
    </StyledTreeItem>
  ));
}

function ComponentsList(props) {
  const classes = useStyles();
  useEffect(() => {
    if (props.expandedPanel === "panel1") {
      props.getModuleComponentsDispatch({
        mid: props.id,
        filter: props.filter
      });
    } else if (props.expandedPanel === "panel2") {
      props.getTechnologyComponentsDispatch({
        tid: props.id,
        filter: props.filter
      });
    }
  }, [
    props.expandedPanel,
    props.id,
    props.filter.resource,
    props.filter.expertise
  ]);

  const handleChange = (event, nodes) => {
    props.setExpandedNodesDispatch(nodes);
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <ListSubheader className={classes.heading}>
          <Typography variant="h6">SUB-MODULES/COMPONENTS</Typography>
        </ListSubheader>
      </Grid>
      <Grid item xs={2}>
        <ListSubheader className={classes.heading}>
          <Typography variant="h6">EXPERT</Typography>
        </ListSubheader>
      </Grid>
      <Grid item xs={3}>
        <ListSubheader className={classes.heading}>
          <Typography variant="h6">KNOWS THE STUFF</Typography>
        </ListSubheader>
      </Grid>
      <Grid item xs={3}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <ListSubheader className={classes.headingDontknow}>
              <Typography variant="h6">DON'T KNOW</Typography>
            </ListSubheader>
          </Grid>
          <Grid item>
            <Grid container alignItems="flex-end">
              <Tooltip title="Collapse all">
                <Button onClick={props.collapseAllDispatch}>
                  <ReorderIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Expand all">
                <Button
                  onClick={props.expandAllDispatch}
                  style={{ transform: "scaleX(-1)" }}
                >
                  <ClearAllIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ height: "calc(100vh - 225px)", overflow: "hidden scroll" }}
      >
        {props.componentsInfo.loading ? (
          <LinearProgress />
        ) : props.componentsInfo.error ? (
          <Alert severity="error">
            Something went wrong while loading components!
          </Alert>
        ) : props.components.length === 0 ? (
          props.filter.resource !== "" || props.filter.expertise !== "" ? (
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{ height: "60%" }}
            >
              <Grid item>
                <Typography>No Components for this filter</Typography>
              </Grid>
            </Grid>
          ) : props.expandedPanel === "panel1" ? (
            <AddModuleComponentPopOver mid={props.id} pid={props.pid} />
          ) : props.expandedPanel === "panel2" ? (
            <AddTechnologyComponentPopOver tid={props.id} pid={props.pid} />
          ) : (
            "test2"
          )
        ) : (
          <TreeView
            expanded={props.expandedNodes}
            className={classes.root}
            defaultCollapseIcon="-"
            defaultExpandIcon="+"
            onNodeToggle={handleChange}
          >
            <Tree
              setExpanded={props.setExpandedNodesDispatch}
              id={props.id}
              pid={props.pid}
              components={props.components}
              height={0}
              expandedNodes={props.expandedNodes}
            />
          </TreeView>
        )}
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getModuleComponentsDispatch: e =>
      dispatch(getModuleComponentsAsyncAction(e)),
    getTechnologyComponentsDispatch: e =>
      dispatch(getTechnologyComponentsAsyncAction(e)),
    setExpandedNodesDispatch: e => dispatch(setExpandedNodesAction(e)),
    collapseAllDispatch: () => dispatch(setAllNodesCollapsed()),
    expandAllDispatch: () => dispatch(setAllNodesExpanded())
  };
};

const mapStateToProps = state => {
  return {
    components: state.componentReducer.components,
    filter: state.componentReducer.filter,
    expandedNodes: state.componentReducer.expandedNodes,
    expandedPanel: state.sidebarReducer.expandedPanel,
    componentsInfo: {
      loading: state.componentReducer.loading,
      error: state.componentReducer.error
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentsList);
