import { BASEURL } from "../constants/constants";
import history from "../helpers/history";

export const componentServices = {
  addModuleSubComponent,
  addTechnologySubComponent,
  editModuleComponent,
  editTechnologyComponent,
  addModuleComponentExpertise,
  addTechnologyComponentExpertise,
  getTechnologyComponents,
  getModuleComponents
};

function editTechnologyComponent(cid, cname) {
  return fetch(`${BASEURL}/technology/component`, {
    method: "PUT",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cname: cname,
      cid: parseInt(cid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function editModuleComponent(cid, cname) {
  return fetch(`${BASEURL}/module/component`, {
    method: "PUT",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cname: cname,
      cid: parseInt(cid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function addModuleSubComponent(cname, cid, mid, pid) {
  return fetch(`${BASEURL}/module/subcomponent`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cname: cname,
      pid: parseInt(pid),
      mid: parseInt(mid),
      parentid: parseInt(cid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function addTechnologySubComponent(cname, cid, tid, pid) {
  return fetch(`${BASEURL}/technology/subcomponent`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cname: cname,
      pid: parseInt(pid),
      tid: parseInt(tid),
      parentid: parseInt(cid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function addModuleComponentExpertise(level, cid) {
  return fetch(`${BASEURL}/module/component/expertise`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      level: parseInt(level),
      cid: parseInt(cid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function addTechnologyComponentExpertise(level, cid) {
  return fetch(`${BASEURL}/technology/component/expertise`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      level: parseInt(level),
      cid: parseInt(cid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function getModuleComponents(data) {
  let url = `${BASEURL}/module/components?mid=${data.mid}`;
  if (data.filter.resource !== "") url += `&resource=${data.filter.resource}`;
  if (data.filter.expertise !== "")
    url += `&expertise=${data.filter.expertise}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function getTechnologyComponents(data) {
  let url = `${BASEURL}/technology/components?tid=${data.tid}`;
  if (data.filter.resource !== "") url += `&resource=${data.filter.resource}`;
  if (data.filter.expertise !== "")
    url += `&expertise=${data.filter.expertise}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function handleResponse(response) {
  return response.json().then(text => {
    let data = text;
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("user");
        history.push("/sign-in");
      }
      if (response.status === 400) {
        history.push("/notFound");
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
