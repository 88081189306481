import initialState, { technologyConstants } from "../constants/constants.js";

const technologyReducer = (state = initialState.technologyReducer, action) => {
  const newState = { ...state };

  switch (action.type) {
    case technologyConstants.FETCH_TECHNOLOGIES_REQUEST: {
      newState.technologies = [];
      newState.loading = true;
      newState.error = false;
      break;
    }
    case technologyConstants.FETCH_TECHNOLOGIES_SUCCESS: {
      newState.technologies = action.data;
      if (newState.technologies.length === 0) {
        newState.selectedTechnology = 0;
      } else if (newState.selectedTechnology === 0) {
        newState.selectedTechnology = newState.technologies[0].Tid;
      }
      newState.loading = false;
      newState.error = false;
      break;
    }
    case technologyConstants.FETCH_TECHNOLOGIES_FAILURE: {
      newState.technologies = [];
      newState.loading = false;
      newState.error = true;
      break;
    }
    case technologyConstants.SET_SELECTED_TECHNOLOGY: {
      newState.selectedTechnology = action.data;
      break;
    }
    case technologyConstants.RESET_TECHNOLOGIES_REDUCER: {
      return initialState.technologyReducer;
      break;
    }
    default: {
    }
  }
  return newState;
};

export default technologyReducer;
