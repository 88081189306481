import initialState, { projectConstants } from "../constants/constants.js";

const projectReducer = (state = initialState.projectReducer, action) => {
  const newState = { ...state };

  switch (action.type) {
    case projectConstants.FETCH_PROJECTS_REQUEST: {
      newState.projects = { projects: [], alerts: 0 };
      newState.loading = true;
      newState.error = false;
      break;
    }
    case projectConstants.FETCH_PROJECTS_SUCCESS: {
      let alerts = { Alerts: 0 };
      if (action.data.length !== 0)
        alerts = action.data.reduce(function(previousValue, currentValue) {
          return {
            Alerts: previousValue.Alerts + currentValue.Alerts
          };
        });
      newState.projects = { projects: action.data, alerts: alerts };
      newState.loading = false;
      break;
    }
    case projectConstants.FETCH_PROJECTS_FAILURE: {
      newState.projects = { projects: [], alerts: 0 };
      newState.loading = false;
      newState.error = true;
      break;
    }
    default: {
    }
  }
  return newState;
};

export default projectReducer;
