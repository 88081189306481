import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import WarningIcon from "@material-ui/icons/Warning";
import { ProjectFilter, ProjectFilterPopup } from "./components";
import history from "../../helpers/history";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ViewListIcon from "@material-ui/icons/ViewList";
import { connect } from "react-redux";
import { useMediaQuery } from "@material-ui/core";

import "./ProjectToolbar.css";

const useStyles = makeStyles(theme => ({
  content: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("lg")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    minHeight: "80px"
  },
  title: {
    fontSize: 30
  },
  alerttext: {
    fontSize: "18px",
    width: "86px",
    backgroundColor: "#E85555",
    padding: "5px",
    borderRadius: "25px",
    textTransform: "uppercase"
  },
  safetext: {
    fontSize: 18,
    backgroundColor: "rgb(107, 189, 112)",
    padding: "5px",
    borderRadius: "25px",
    textTransform: "uppercase"
  },
  alert: {
    marginLeft: "1%"
  },
  centeringclass: {
    alignItems: "center"
  },
  button: {
    width: "200px",
    backgroundColor: "black",
    height: "36px",
    color: "white",
    "&:hover": {
      backgroundColor: "#E85555"
    }
  }
}));

const ProjectToolbar = props => {
  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });

  return (
    <div className="projecttoolbarroot">
      <div className={classes.content}>
        <Toolbar className={classes.content}>
          <Grid
            container
            className={classes.centeringclass}
            justify="space-between"
          >
            <Grid item xs={6}>
              <Grid container className={classes.centeringclass}>
                <Grid item>
                  <IconButton
                    edge="start"
                    aria-label="menu"
                    onClick={() => history.push("/home")}
                  >
                    <ArrowBackIosIcon className="projecttoolbararrowbutton" />
                  </IconButton>
                </Grid>
                {isDesktop && (
                  <Grid item>
                    <Typography variant="h3" className={classes.title}>
                      {props.match.params.name}
                    </Typography>
                  </Grid>
                )}
                {parseInt(props.alert) !== 0 ? (
                  <Grid item className={classes.alert}>
                    <Typography
                      align="left"
                      variant="h5"
                      className={classes.alerttext}
                      style={{ color: "white" }}
                    >
                      <WarningIcon
                        style={{
                          fontSize: "22px",
                          verticalAlign: "middle",
                          color: "white",
                          marginLeft: "5px",
                          paddingBottom: "3px"
                        }}
                      />
                      &nbsp;&nbsp; {props.alert}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item className={classes.alert}>
                    <Typography
                      align="left"
                      variant="h5"
                      className={classes.safetext}
                      style={{ color: "white" }}
                    >
                      <CheckCircleIcon
                        style={{
                          fontSize: "22px",
                          verticalAlign: "middle",
                          color: "white"
                        }}
                      />
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isDesktop
              ? props.expandedPanel !== "panel3" && (
                  <ProjectFilter match={props.match} />
                )
              : props.expandedPanel !== "panel3" && (
                  <Grid item>
                    <Grid container spacing={3}>
                      {props.expandedPanel !== "resources" && (
                        <Grid item>
                          <ProjectFilterPopup match={props.match} />
                        </Grid>
                      )}
                      <Grid item>
                        <IconButton
                          style={{ border: "1px solid" }}
                          onClick={props.handleSidebarOpen}
                        >
                          <ViewListIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
          </Grid>
        </Toolbar>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    alert: state.moduleReducer.totalAlert,
    expandedPanel: state.sidebarReducer.expandedPanel
  };
};

export default connect(mapStateToProps, null)(ProjectToolbar);
