import initialState, { moduleConstants } from "../constants/constants.js";

const moduleReducer = (state = initialState.moduleReducer, action) => {
  const newState = { ...state };

  switch (action.type) {
    case moduleConstants.FETCH_MODULES_REQUEST: {
      newState.modules = [];
      newState.totalAlert = 0;
      newState.loading = true;
      newState.error = false;
      break;
    }
    case moduleConstants.FETCH_MODULES_SUCCESS: {
      newState.modules = action.data;
      let alerts = { Alerts: 0 };
      if (newState.modules.length === 0) {
        newState.selectedModule = 0;
        newState.totalAlert = alerts.Alerts;
        newState.loading = false;
        newState.error = false;
        break;
      } else if (newState.selectedModule === 0) {
        newState.selectedModule = newState.modules[0].Mid;
      }
      alerts = newState.modules.reduce(function(previousValue, currentValue) {
        return {
          Alerts: previousValue.Alerts + currentValue.Alerts
        };
      });
      newState.totalAlert = alerts.Alerts;
      newState.loading = false;
      newState.error = false;
      break;
    }
    case moduleConstants.FETCH_MODULES_FAILURE: {
      newState.modules = [];
      newState.totalAlert = 0;
      newState.loading = false;
      newState.error = true;
      break;
    }
    case moduleConstants.SET_SELECTED_MODULE: {
      newState.selectedModule = action.data;
      break;
    }
    case moduleConstants.RESET_MODULES_REDUCER: {
      return initialState.moduleReducer;
    }
    default: {
    }
  }
  return newState;
};

export default moduleReducer;
