import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AddPopOver } from "../../components";
import { connect } from "react-redux";

import { technologiesServices } from "../../services/technologiesservices";
import {
  setTechnologiesAction,
  getTechnologiesRequestAction
} from "../../actions/technologyactions";
import { setAlertsAction } from "../../actions/alertactions";

function AddTechnologyPopOver(props) {
  const handleSubmits = formData => {
    props.getTechnologiesRequestDispatch();
    technologiesServices.addTechnology(formData, props.pid).then(
      data => props.setTechnologiesDispatch(data),
      error =>
        props.setAlertsDispatch({
          message: "Failed to add technology",
          title: "Alert",
          open: true
        })
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Typography variant="h4">
            No technologies in this project. You want to add some?
          </Typography>
        </Grid>
        <br />
        <Grid item>
          <AddPopOver
            buttonText="Add Technology"
            title="Add Technology"
            handleSubmit={handleSubmits}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setTechnologiesDispatch: e => dispatch(setTechnologiesAction(e)),
    getTechnologiesRequestDispatch: () =>
      dispatch(getTechnologiesRequestAction()),
    setAlertsDispatch: e => dispatch(setAlertsAction(e))
  };
};

export default connect(null, mapDispatchToProps)(AddTechnologyPopOver);
