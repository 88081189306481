import React from "react";
import { Button } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import { connect } from "react-redux";

import {
  getModuleComponentsAsyncAction,
  getModulesAsyncAction
} from "../../actions/moduleactions";

import { getTechnologyComponentsAsyncAction } from "../../actions/technologyactions";

import { setAlertsAction } from "../../actions/alertactions";

import { moduleServices } from "../../services/modulesservices";
import { technologiesServices } from "../../services/technologiesservices";
import { componentServices } from "../../services/componentsservices";

import { EditPopOver, AddPopOver, ConfirmWindow } from "../../components";

function ComponentsMenu(props) {
  const { expandedPanel } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    setAnchorEl(null);
  };

  const deleteComponent = event => {
    if (expandedPanel === "panel1") {
      moduleServices.deleteModuleComponent(props.item.Cid).then(
        data => {
          props.getModuleComponentsDispatch({
            mid: props.id,
            filter: { resource: "", expertise: "" },
            state: "update"
          });
          props.getModulesDispatch({ pid: props.pid, state: "" });
        },
        err => {
          props.setAlertsDispatch({
            message: "Failed to delete module component",
            title: "Alert",
            open: true
          });
        }
      );
    } else if (expandedPanel === "panel2") {
      technologiesServices.deleteTechnologyComponent(props.item.Cid).then(
        data => {
          props.getTechnologyComponentsDispatch({
            tid: props.id,
            filter: { resource: "", expertise: "" },
            state: "update"
          });
        },
        err =>
          props.setAlertsDispatch({
            message: "Failed to delete technology component",
            title: "Alert",
            open: true
          })
      );
    }
    handleClose(event);
  };

  const addSubComponent = formData => {
    if (expandedPanel === "panel1") {
      componentServices
        .addModuleSubComponent(formData, props.item.Cid, props.id, props.pid)
        .then(
          data => {
            props.getModuleComponentsDispatch({
              mid: props.id,
              filter: { resource: "", expertise: "" },
              state: "update"
            });
            props.getModulesDispatch({ pid: props.pid, state: "update" });
          },
          err =>
            props.setAlertsDispatch({
              message: "Failed to add module sub-component",
              title: "Alert",
              open: true
            })
        );
    } else if (expandedPanel === "panel2") {
      componentServices
        .addTechnologySubComponent(
          formData,
          props.item.Cid,
          props.id,
          props.pid
        )
        .then(
          data => {
            props.getTechnologyComponentsDispatch({
              tid: props.id,
              filter: { resource: "", expertise: "" },
              state: "update"
            });
          },
          err =>
            props.setAlertsDispatch({
              message: "Failed to add technology sub-component",
              title: "Alert",
              open: true
            })
        );
    }
  };

  const editComponent = formData => {
    if (expandedPanel === "panel1") {
      componentServices.editModuleComponent(props.item.Cid, formData).then(
        data => {
          props.getModuleComponentsDispatch({
            mid: props.id,
            filter: { resource: "", expertise: "" },
            state: "update"
          });
        },
        err =>
          props.setAlertsDispatch({
            message: "Failed to edit module component",
            title: "Alert",
            open: true
          })
      );
    } else if (expandedPanel === "panel2") {
      componentServices.editTechnologyComponent(props.item.Cid, formData).then(
        data => {
          props.getTechnologyComponentsDispatch({
            tid: props.id,
            filter: { resource: "", expertise: "" },
            state: "update"
          });
        },
        err =>
          props.setAlertsDispatch({
            message: "Failed to edit technology component",
            title: "Alert",
            open: true
          })
      );
    }
  };

  return (
    <div>
      <Button
        style={{ padding: 0, margin: 0, minWidth: 0 }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {props.buttonText}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ConfirmWindow
          buttonText="Delete Component"
          handleSubmit={deleteComponent}
          message="You want to delete the component"
        />
        <AddPopOver
          buttonText="Add Subcomponent"
          title="Add Subcomponent"
          handleSubmit={addSubComponent}
        />
        <EditPopOver
          buttonText="Edit Component"
          title="Edit Component"
          formData={props.item.Cname}
          handleSubmit={editComponent}
        />
      </Menu>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getModuleComponentsDispatch: e =>
      dispatch(getModuleComponentsAsyncAction(e)),
    getTechnologyComponentsDispatch: e =>
      dispatch(getTechnologyComponentsAsyncAction(e)),
    setAlertsDispatch: e => dispatch(setAlertsAction(e)),
    getModulesDispatch: e => dispatch(getModulesAsyncAction(e))
  };
};

const mapStateToProps = state => {
  return {
    expandedPanel: state.sidebarReducer.expandedPanel
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentsMenu);
