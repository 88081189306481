import { resourceConstants } from "../constants/constants.js";

export function getProjectUsersAsyncAction(data) {
  return { type: resourceConstants.FETCH_PROJECT_USERS, data: data };
}

export function setProjectUsersAction(data) {
  return { type: resourceConstants.FETCH_PROJECT_USERS_SUCCESS, data: data };
}

export function getProjectUsersFailureAction() {
  return { type: resourceConstants.FETCH_PROJECT_USERS_FAILURE };
}

export function getProjectUsersRequestAction() {
  return { type: resourceConstants.FETCH_PROJECT_USERS_REQUEST };
}

export function setSelectedResourceAction(data) {
  return { type: resourceConstants.SET_SELECTED_RESOURCE, data: data };
}

export function resetResourcesReducerAction() {
  return { type: resourceConstants.RESET_RESOURCES_REDUCER };
}
