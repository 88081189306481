import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";

import {
  setProjectsAction,
  getProjectsRequestAction
} from "../../../../../../../../actions/projectactions";
import { setAlertsAction } from "../../../../../../../../actions/alertactions";

import { projectServices } from "../../../../../../../../services/projectservices";

function EditPopOver(props) {
  React.useEffect(() => {
    setFormData(props.formData);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [formDatas, setFormData] = React.useState({ name: "", minUsers: "" });

  const handleClickOpen = event => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleChange = event => {
    event.stopPropagation();
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };

  const handleClose = event => {
    event.stopPropagation();
    setOpen(false);
  };

  const {
    buttonText,
    formData,
    setProjectsDispatch,
    getProjectsRequestAction,
    ...rest
  } = props;

  const handleSubmit = event => {
    event.preventDefault();
    let data = event.target;
    getProjectsRequestAction();
    projectServices
      .editProject(data.name.value, parseInt(data.minUsers.value), formData.pid)
      .then(
        data => {
          setProjectsDispatch(data);
        },
        err =>
          props.setAlertsDispatch({
            message: "Failed to edit project",
            title: "Alert",
            open: true
          })
      );
    handleClose(event);
  };

  return (
    <div onClick={event => event.stopPropagation()}>
      <Button onClick={handleClickOpen}>{buttonText}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Project</DialogTitle>
        <DialogContent>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div>
              <TextField
                id="name"
                required
                value={formDatas.name}
                onChange={handleChange}
                label="Name"
                margin="normal"
              />
            </div>
            <Divider />
            <div>
              <TextField
                style={{ width: "100%" }}
                required
                onChange={handleChange}
                value={formDatas.minUsers}
                id="minUsers"
                label="Mininum users per component"
                type="number"
                InputProps={{ inputProps: { min: 1, max: 10 } }}
              />
            </div>
            <br />
            <br />
            <Button type="submit">Apply</Button>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setProjectsDispatch: e => dispatch(setProjectsAction(e)),
    getProjectsRequestAction: () => dispatch(getProjectsRequestAction()),
    setAlertsDispatch: e => dispatch(setAlertsAction(e))
  };
};

export default connect(null, mapDispatchToProps)(EditPopOver);
