import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { ProjectCard } from "./components";
import { ProjectToolbar } from "./components";

import { getProjectsAsyncAction } from "../../actions/projectactions";

import { resetTechnologiesReducerAction } from "../../actions/technologyactions";
import { resetModulesReducerAction } from "../../actions/moduleactions";
import { resetResourcesReducerAction } from "../../actions/resourceactions";
import { resetSidebarReducerAction } from "../../actions/sidebaractions";
import { resetComponentsReducerAction } from "../../actions/componentactions";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2)
    }
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ProjectList = props => {
  const classes = useStyles();

  useEffect(() => {
    props.getProjectsDispatch();
    props.resetTechnologiesReducerDispatch();
    props.resetModulesReducerDispatch();
    props.resetResourcesReducerDispatch();
    props.resetSidebarReducerDispatch();
    props.resetComponentsReducerDispatch();
  }, []);

  return (
    <div className={classes.root}>
      <ProjectToolbar alerts={props.projects.alerts} />
      {props.error && (
        <Alert severity="error">
          Something went wrong while loading projects!
        </Alert>
      )}
      <div className={classes.content}>
        {props.loading ? (
          <LinearProgress />
        ) : (
          <Grid container spacing={4}>
            {props.projects.projects.map(project => (
              <Grid item key={project.Pid} lg={4} md={6} xs={12}>
                <ProjectCard project={project} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getProjectsDispatch: () => dispatch(getProjectsAsyncAction()),
    resetTechnologiesReducerDispatch: () =>
      dispatch(resetTechnologiesReducerAction()),
    resetModulesReducerDispatch: () => dispatch(resetModulesReducerAction()),
    resetResourcesReducerDispatch: () =>
      dispatch(resetResourcesReducerAction()),
    resetSidebarReducerDispatch: () => dispatch(resetSidebarReducerAction()),
    resetComponentsReducerDispatch: () =>
      dispatch(resetComponentsReducerAction())
  };
};

const mapStateToProps = state => {
  return {
    projects: state.projectReducer.projects,
    error: state.projectReducer.error,
    loading: state.projectReducer.loading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
