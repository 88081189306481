import React from "react";
import { Button } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { connect } from "react-redux";

import {
  setModulesAction,
  getModuleComponentsAsyncAction,
  getModulesRequestAction
} from "../../actions/moduleactions";

import { moduleServices } from "../../services/modulesservices";
import { EditPopOver, AddPopOver, ConfirmWindow } from "../../components";

import { setAlertsAction } from "../../actions/alertactions";

function ModulesMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    setAnchorEl(null);
  };

  const deleteModule = event => {
    props.getModulesRequestDispatch();
    moduleServices.deleteModule(props.item.Mid, props.pid).then(
      data => {
        props.setModulesDispatch(data);
      },
      err =>
        props.setAlertsDispatch({
          message: "Failed to delete module",
          title: "Alert",
          open: true
        })
    );
    handleClose(event);
  };

  const editModule = formData => {
    props.getModulesRequestDispatch();
    moduleServices.editModule(formData, props.item.Mid, props.pid).then(
      data => {
        props.setModulesDispatch(data);
      },
      err =>
        props.setAlertsDispatch({
          message: "Failed to edit module",
          title: "Alert",
          open: true
        })
    );
  };

  const addComponent = formData => {
    moduleServices.addModuleComponent(formData, props.item.Mid, props.pid).then(
      data =>
        props.getModuleComponentsDispatch({
          mid: props.item.Mid,
          filter: { resource: "", expertise: "" },
          state: "update"
        }),
      error =>
        props.setAlertsDispatch({
          message: "Failed to add module component",
          title: "Alert",
          open: true
        })
    );
  };

  return (
    <div>
      <Button
        style={{ padding: 0, margin: 0, minWidth: 0 }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {props.buttonText}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ConfirmWindow
          buttonText="Delete Module"
          handleSubmit={deleteModule}
          message="You want to delete the module"
        />
        <AddPopOver
          buttonText="Add Component"
          title="Add Component"
          handleSubmit={addComponent}
        />
        <EditPopOver
          buttonText="Edit Module"
          title="Edit Module"
          formData={props.item.Mname}
          handleSubmit={editModule}
        />
      </Menu>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setModulesDispatch: e => dispatch(setModulesAction(e)),
    getModuleComponentsDispatch: e =>
      dispatch(getModuleComponentsAsyncAction(e)),
    getModulesRequestDispatch: () => dispatch(getModulesRequestAction()),
    setAlertsDispatch: e => dispatch(setAlertsAction(e))
  };
};

export default connect(null, mapDispatchToProps)(ModulesMenu);
