import { moduleConstants } from "../constants/constants.js";

export function getModulesAsyncAction(data) {
  return { type: moduleConstants.FETCH_MODULES, data: data };
}

export function setModulesAction(data) {
  return { type: moduleConstants.FETCH_MODULES_SUCCESS, data: data };
}

export function getModulesFailureAction() {
  return { type: moduleConstants.FETCH_MODULES_FAILURE };
}

export function getModulesRequestAction() {
  return { type: moduleConstants.FETCH_MODULES_REQUEST };
}

export function getModuleComponentsAsyncAction(data) {
  return { type: moduleConstants.FETCH_MODULE_COMPONENTS, data: data };
}

export function setSelectedModuleAction(data) {
  return { type: moduleConstants.SET_SELECTED_MODULE, data: data };
}

export function resetModulesReducerAction() {
  return { type: moduleConstants.RESET_MODULES_REDUCER };
}
