import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";

import { getProjectUsersAsyncAction } from "../../../../actions/resourceactions";
import { setFilterAction } from "../../../../actions/componentactions";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const ProjectFilter = props => {
  const {
    filter,
    setFilterDispatch,
    getProjectUsersDispatch,
    projectUsers
  } = props;
  const classes = useStyles();
  const handleChange = event => {
    setFilterDispatch({
      ...filter,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    getProjectUsersDispatch(props.match.params.pid);
  }, []);

  return (
    <Grid item xs={6}>
      <FormControl
        className={classes.formControl}
        style={{ width: "150px", margin: "10px" }}
      >
        <InputLabel name="ewfe" id="demo-simple-select-label">
          Resource
        </InputLabel>
        <Select
          name="resource"
          id="demo-simple-select"
          value={filter.resource}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {projectUsers.map(item => (
            <MenuItem key={item.Eid} value={item.Eid}>
              {item.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        className={classes.formControl}
        style={{ width: "150px", margin: "10px" }}
      >
        <InputLabel id="demo-simple-select-label">Expertise</InputLabel>
        <Select
          name="expertise"
          id="demo-simple-select"
          value={filter.expertise}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={2}>Knows the stuff</MenuItem>
          <MenuItem value={3}>Expert</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setFilterDispatch: e => dispatch(setFilterAction(e)),
    getProjectUsersDispatch: e => dispatch(getProjectUsersAsyncAction(e))
  };
};

const mapStateToProps = state => {
  return {
    filter: state.componentReducer.filter,
    projectUsers: state.resourceReducer.users
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFilter);
