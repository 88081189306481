import { BASEURL } from "../constants/constants";
import history from "../helpers/history";

export const moduleServices = {
  deleteModuleComponent,
  editModule,
  addModuleComponent,
  deleteModule,
  addModule,
  getModules
};

function getModules(pid) {
  return fetch(`${BASEURL}/modules?pid=${pid}`, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function deleteModule(mid, pid) {
  return fetch(`${BASEURL}/module?mid=${mid}&pid=${pid}`, {
    method: "DELETE",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function deleteModuleComponent(cid) {
  return fetch(`${BASEURL}/module/component?cid=${cid}`, {
    method: "DELETE",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function editModule(mname, mid, pid) {
  return fetch(`${BASEURL}/module`, {
    method: "PUT",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      mname: mname,
      mid: parseInt(mid),
      pid: parseInt(pid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function addModuleComponent(cname, mid, pid) {
  return fetch(`${BASEURL}/module/component`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cname: cname,
      mid: parseInt(mid),
      pid: parseInt(pid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function addModule(mname, pid) {
  return fetch(`${BASEURL}/module`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      mname: mname,
      pid: parseInt(pid)
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function handleResponse(response) {
  return response.json().then(text => {
    let data = text;
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("user");
        history.push("/sign-in");
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
