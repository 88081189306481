import { BASEURL } from "../constants/constants";
import history from "../helpers/history";

export const projectServices = {
  deleteProject,
  editProject,
  getProjects,
  addProject,
  getProjectUsers
};

function getProjects() {
  return fetch(`${BASEURL}/projects`, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function addProject(pname, minusers) {
  return fetch(`${BASEURL}/project`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      pname: pname,
      minusers: minusers
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function editProject(pname, minusers, pid) {
  return fetch(`${BASEURL}/project`, {
    method: "PUT",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      pname: pname,
      minusers: minusers,
      pid: pid
    }),
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function deleteProject(pid) {
  return fetch(`${BASEURL}/project?pid=${pid}`, {
    method: "DELETE",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function getProjectUsers(pid) {
  return fetch(`${BASEURL}/project/users?pid=${pid}`, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("user"),
      "Content-Type": "application/json"
    },
    credentials: "same-origin"
  }).then(response => handleResponse(response));
}

function handleResponse(response) {
  return response.json().then(text => {
    let data = text;
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("user");
        history.push("/sign-in");
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
