import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";

import {
  setProjectsAction,
  getProjectsRequestAction
} from "../../../../../../actions/projectactions";
import { setAlertsAction } from "../../../../../../actions/alertactions";

import { projectServices } from "../../../../../../services/projectservices";

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    zIndex: 50,
    width: "100%",
    backgroundColor: "black",
    color: "white",
    height: "36px",
    "&:hover": {
      backgroundColor: "#E85555"
    }
  }
}));

function AddPopOver(props) {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({ name: "", minUsers: "1" });
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = event => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    buttonText,
    setProjectsDispatch,
    setAlertsDispatch,
    getProjectsRequestDispatch,
    ...rest
  } = props;

  const handleSubmit = formData => {
    formData.preventDefault();
    formData = formData.target;
    getProjectsRequestDispatch();
    projectServices
      .addProject(formData.name.value, parseInt(formData.minUsers.value))
      .then(
        data => {
          setProjectsDispatch(data);
          setFormData({ name: "", minUsers: "1" });
        },
        err =>
          props.setAlertsDispatch({
            message: "Failed to add project",
            title: "Alert",
            open: true
          })
      );
    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={classes.button}
        {...rest}
      >
        {buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Project</DialogTitle>
        <DialogContent>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div>
              <TextField
                id="name"
                required
                value={formData.name}
                onChange={handleChange}
                label="Name"
                margin="normal"
                autoFocus
              />
            </div>
            <Divider />
            <div>
              <TextField
                style={{ width: "100%" }}
                required
                onChange={handleChange}
                value={formData.minUsers}
                id="minUsers"
                label="Mininum users per component"
                type="number"
                InputProps={{ inputProps: { min: 1, max: 10 } }}
              />
            </div>
            <br />
            <br />
            <Button type="submit">Save</Button>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setProjectsDispatch: e => dispatch(setProjectsAction(e)),
    getProjectsRequestDispatch: e => dispatch(getProjectsRequestAction(e)),
    setAlertsDispatch: e => dispatch(setAlertsAction(e))
  };
};

export default connect(null, mapDispatchToProps)(AddPopOver);
