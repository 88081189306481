import React from "react";
import GoogleLogin from "react-google-login";
import { loginServices } from "services/loginservices";
import history from "../../../../helpers/history";

const Google = props => {
  const responseGoogle = response => {
    localStorage.setItem("user-google", response.tokenId);
    loginServices.loginCheck(response.tokenId).then(
      data => {
        localStorage.setItem("user-google", response.tokenId);
        data = data.replace(/"/g, "");
        localStorage.setItem("user", data);
        history.push("/home");
      },
      error => {
        props.setLoginStatus(false);
        alert("Login failed");
        setTimeout(function() {
          props.setLoginStatus(true);
        }, 3000);
      }
    );
  };

  const responseGoogleError = response => {
    props.setLoginStatus(false);
    setTimeout(function() {
      props.setLoginStatus(true);
    }, 3000);
    localStorage.removeItem("user");
    localStorage.removeItem("user-google");
  };

  return (
    <GoogleLogin
      clientId="980659159182-apvg6sqa7gumfgu4eds56egdkun63ekj.apps.googleusercontent.com"
      cookiePolicy={"single_host_origin"}
      onFailure={responseGoogleError}
      onSuccess={responseGoogle}
    />
  );
};

export default Google;
