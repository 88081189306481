import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";

import { Grid, Typography } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { connect } from "react-redux";

import { setModulesAction } from "../../actions/moduleactions";
import { setTechnologiesAction } from "../../actions/technologyactions";
import { setAlertsAction } from "../../actions/alertactions";

import { Sidebar, ComponentsList } from "./components";

import {
  AddModulePopOver,
  AddTechnologyPopOver,
  ProjectToolbar
} from "../../components";

const useStyles = makeStyles(theme => ({
  root: { height: "100%" },
  shiftContent: {
    paddingLeft: 300
  },
  button: {
    color: "grey",
    cursor: "pointer",
    "&:hover": {
      color: "#E85555",
      textDecoration: "underline"
    }
  }
}));

const ProjectItemAdmin = props => {
  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const { selectedModule, selectedTechnology, expandedPanel } = props;

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <ProjectToolbar
          expandedPanel={expandedPanel}
          match={props.match}
          handleSidebarOpen={handleSidebarOpen}
          handleSidebarClose={handleSidebarClose}
        />
      </Grid>
      <Sidebar
        match={props.match}
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? "persistent" : "temporary"}
      />
      <Grid
        style={{
          height: window.innerHeight - 187 + "px",
          overflow: "hidden",
          width: "100%"
        }}
      >
        <div
          className={clsx({
            [classes.root]: true,
            [classes.shiftContent]: isDesktop
          })}
        >
          {expandedPanel === "panel1" ? (
            selectedModule === 0 ? (
              <AddModulePopOver pid={props.match.params.pid} />
            ) : (
              <ComponentsList
                id={selectedModule}
                pid={props.match.params.pid}
              />
            )
          ) : expandedPanel === "panel2" ? (
            selectedTechnology === 0 ? (
              <AddTechnologyPopOver pid={props.match.params.pid} />
            ) : (
              <ComponentsList
                id={selectedTechnology}
                pid={props.match.params.pid}
              />
            )
          ) : (
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              style={{ height: "100%" }}
            >
              <Grid item>
                <Typography variant="h4">
                  You have to be an admin to view resources page
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getModulesDispatch: e => dispatch(setModulesAction(e)),
    getTechnologiesDispatch: e => dispatch(setTechnologiesAction(e)),
    setAlertsDispatch: e => dispatch(setAlertsAction(e))
  };
};

const mapStateToProps = state => {
  return {
    expandedPanel: state.sidebarReducer.expandedPanel,
    selectedModule: state.moduleReducer.selectedModule,
    selectedTechnology: state.technologyReducer.selectedTechnology,
    alert: state.projectReducer.alert
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectItemAdmin);
