import { combineReducers } from "redux";

import projectReducer from "./projectreducer.js";
import moduleReducer from "./modulereducer.js";
import componentReducer from "./componentreducer.js";
import technologyReducer from "./technologyreducer.js";
import resourceReducer from "./resourcereducer.js";
import sidebarReducer from "./sidebarreducer.js";

import alertReducer from "./alertreducer.js";

const reducer = combineReducers({
  projectReducer,
  moduleReducer,
  componentReducer,
  technologyReducer,
  resourceReducer,
  sidebarReducer,
  alertReducer
});

export default reducer;
