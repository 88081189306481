import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";
import history from "./helpers/history";

import {
  NotFound as NotFoundView,
  ProjectList as ProjectListView,
  SignIn as SignInView,
  ProjectItemAdmin as ProjectItemAdminView,
  ProjectItemUser as ProjectItemUserView
} from "./views";
const jwt = require("jsonwebtoken");

const loggedIn = () => {
  if (localStorage.getItem("user") == null) {
    return false;
  }
  return true;
};

const requireAuth = (nextState, replace) => {
  if (!loggedIn()) {
    replace({
      pathname: "/sign-in"
    });
  }
};

const requireAdminAuth = (nextState, replace) => {
  requireAuth(nextState, replace);
  if (!jwt.decode(localStorage.getItem("user")).isadmin) {
    replace({
      pathname: "/sign-in"
    });
  }
};

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={ProjectListView}
        exact
        layout={MainLayout}
        path="/home"
        onEnter={requireAuth}
      />
      <RouteWithLayout
        component={ProjectItemAdminView}
        exact
        layout={MainLayout}
        path="/project/admin/:pid/:name"
        onEnter={requireAdminAuth}
      />
      <RouteWithLayout
        component={ProjectItemUserView}
        exact
        layout={MainLayout}
        path="/project/user/:pid/:name"
        onEnter={requireAuth}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect from="/" to="/sign-in" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
