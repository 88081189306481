import { technologyConstants } from "../constants/constants.js";

export function getTechnologiesAsyncAction(data) {
  return { type: technologyConstants.FETCH_TECHNOLOGIES, data: data };
}

export function setTechnologiesAction(data) {
  return { type: technologyConstants.FETCH_TECHNOLOGIES_SUCCESS, data: data };
}

export function getTechnologiesFailureAction() {
  return { type: technologyConstants.FETCH_TECHNOLOGIES_FAILURE };
}

export function getTechnologiesRequestAction() {
  return { type: technologyConstants.FETCH_TECHNOLOGIES_REQUEST };
}

export function getTechnologyComponentsAsyncAction(data) {
  return { type: technologyConstants.FETCH_TECHNOLOGY_COMPONENTS, data: data };
}

export function setSelectedTechnologyAction(data) {
  return { type: technologyConstants.SET_SELECTED_TECHNOLOGY, data: data };
}

export function resetTechnologiesReducerAction() {
  return { type: technologyConstants.RESET_TECHNOLOGIES_REDUCER };
}
