import initialState, { sidebarConstants } from "../constants/constants.js";

const sidebarReducer = (state = initialState.sidebarReducer, action) => {
  const newState = { ...state };

  switch (action.type) {
    case sidebarConstants.SET_EXPANDED_PANEL: {
      newState.expandedPanel = action.data;
      break;
    }
    case sidebarConstants.RESET_SIDEBAR_REDUCER: {
      return initialState.sidebarReducer;
      break;
    }
    default: {
    }
  }
  return newState;
};

export default sidebarReducer;
