export const BASEURL = "http://ktracker.qburst.build/v1/api";

export const alertConstants = {
  SET_ALERT: "SET_ALERT"
};

export const projectConstants = {
  FETCH_PROJECTS: "FETCH_PROJECTS",
  FETCH_PROJECTS_SUCCESS: "FETCH_PROJECTS_SUCCESS",
  FETCH_PROJECTS_FAILURE: "FETCH_PROJECTS_FAILURE",
  FETCH_PROJECTS_REQUEST: "FETCH_PROJECTS_REQUEST"
};

export const moduleConstants = {
  FETCH_MODULES: "FETCH_MODULES",
  FETCH_MODULES_SUCCESS: "FETCH_MODULES_SUCCESS",
  FETCH_MODULES_FAILURE: "FETCH_MODULES_FAILURE",
  FETCH_MODULES_REQUEST: "FETCH_MODULES_REQUEST",
  FETCH_MODULE_COMPONENTS: "FETCH_MODULE_COMPONENTS",
  SET_SELECTED_MODULE: "SET_SELECTED_MODULE",
  RESET_MODULES_REDUCER: "RESET_MODULES_REDUCER"
};

export const componentConstants = {
  FETCH_COMPONENTS_SUCCESS: "FETCH_COMPONENTS_SUCCESS",
  FETCH_COMPONENTS_FAILURE: "FETCH_COMPONENTS_FAILURE",
  FETCH_COMPONENTS_REQUEST: "FETCH_COMPONENTS_REQUEST",
  SET_FILTER: "SET_FILTER",
  SET_EXPANDED_NODES: "SET_EXPANDED_NODES",
  EXPAND_ALL_NODES: "EXPAND_ALL_NODES",
  COLLAPSE_ALL_NODES: "COLLAPSE_ALL_NODES",
  RESET_COMPONENTS_REDUCER: "RESET_COMPONENTS_REDUCER"
};

export const technologyConstants = {
  FETCH_TECHNOLOGIES: "FETCH_TECHNOLOGIES",
  FETCH_TECHNOLOGIES_SUCCESS: "FETCH_TECHNOLOGIES_SUCCESS",
  FETCH_TECHNOLOGIES_FAILURE: "FETCH_TECHNOLOGIES_FAILURE",
  FETCH_TECHNOLOGIES_REQUEST: "FETCH_TECHNOLOGIES_REQUEST",
  FETCH_TECHNOLOGY_COMPONENTS: "FETCH_TECHNOLOGY_COMPONENTS",
  SET_SELECTED_TECHNOLOGY: "SET_SELECTED_TECHNOLOGY",
  RESET_TECHNOLOGIES_REDUCER: "RESET_TECHNOLOGIES_REDUCER"
};

export const resourceConstants = {
  FETCH_PROJECT_USERS: "FETCH_PROJECT_USERS",
  FETCH_PROJECT_USERS_SUCCESS: "FETCH_PROJECT_USERS_SUCCESS",
  FETCH_PROJECT_USERS_FAILURE: "FETCH_PROJECT_USERS_FAILURE",
  FETCH_PROJECT_USERS_REQUEST: "FETCH_PROJECT_USERS_REQUEST",
  SET_SELECTED_RESOURCE: "SET_SELECTED_RESOURCE",
  RESET_RESOURCES_REDUCER: "RESET_RESOURCES_REDUCER"
};

export const sidebarConstants = {
  SET_EXPANDED_PANEL: "SET_EXPANDED_PANEL",
  RESET_SIDEBAR_REDUCER: "RESET_SIDEBAR_REDUCER"
};

const projectReducer = {
  projects: { projects: [], alerts: 0 },
  loading: true,
  error: false
};

const moduleReducer = {
  modules: [],
  totalAlert: 0,
  selectedModule: 0,
  loading: true,
  error: false
};

const technologyReducer = {
  technologies: [],
  selectedTechnology: 0,
  loading: true,
  error: false
};

const componentReducer = {
  components: [],
  filter: { expertise: "", resource: "" },
  expandedNodes: [],
  loading: true,
  error: false
};

const resourceReducer = {
  users: [],
  selectedResource: "members"
};

const sidebarReducer = {
  expandedPanel: "panel1"
};

const alertReducer = {
  alert: { message: "", open: false, title: "" }
};

const initialState = {
  projectReducer,
  moduleReducer,
  componentReducer,
  technologyReducer,
  resourceReducer,
  sidebarReducer,
  alertReducer
};

export default initialState;
