import React from "react";
import { Grid, IconButton, Button } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

import { ProjectFilter } from "../";

const ProjectFilterPopup = props => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = event => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton style={{ border: "1px solid" }} onClick={handleClickOpen}>
        <FilterListIcon />
      </IconButton>
      <Dialog
        onClick={event => {
          event.stopPropagation();
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container justify="space-between">
            Filter Components
            <Button onClick={handleClose} style={{ padding: 0, margin: 0 }}>
              <CloseIcon />
            </Button>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <ProjectFilter match={props.match} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default ProjectFilterPopup;
