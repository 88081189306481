import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "react-avatar";
import AvatarStack from "react-avatar-stack";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import "./ResourceView.css";

const useStyles = makeStyles(theme => ({
  root: {},
  tablecell: {
    padding: "6px",
    borderBottom: "none",
    "&:hover": {
      backgroundColor: "#F2FAFF",
      boxShadow: "2px 2px #00000029"
    }
  },
  tablerow: {
    "&:hover": {
      backgroundColor: "#F2FAFF",
      boxShadow: "2px 2px #00000029"
    }
  }
}));

function ResourceView(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { buttonText, users, ...rest } = props;

  const handleClickOpen = event => {
    event.stopPropagation();
    setOpen(true);
  };
  const handleClose = event => {
    event.stopPropagation();
    setOpen(false);
  };

  return (
    <div>
      <button
        className="resourceview-button"
        onClick={handleClickOpen}
        {...rest}
      >
        <AvatarStack
          numberLeftColor="white"
          numberLeftBackgroundColor="black"
          maxAvatarNumber={6}
          size={10}
        >
          {props.users.map(item => (
            <Avatar
              key={item.Eid}
              size="35px"
              round="35px"
              src={item.Image}
              title={item.Name}
              name={item.Name}
              maxInitials={2}
            />
          ))}
        </AvatarStack>
      </button>
      <Dialog
        onClick={event => event.stopPropagation()}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="form-dialog-title">
          <Grid>
            <Typography variant="h4">Resources</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers style={{ padding: "0px" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow></TableRow>
            </TableHead>
            <TableBody>
              {users.map(item => (
                <TableRow key={item.Eid} hover className={classes.tablerow}>
                  <TableCell className={classes.tablecell}>
                    <Grid container spacing={2} item alignItems="center">
                      <Grid item>
                        <Avatar
                          key={item.Eid}
                          size="35px"
                          round="35px"
                          src={item.Image}
                          title={item.Name}
                          name={item.Name}
                          maxInitials={2}
                        />
                      </Grid>
                      <Grid item>
                        <Typography>{item.Name}</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ResourceView;
