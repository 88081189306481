import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, SvgIcon, Radio } from "@material-ui/core";

import GroupIcon from "@material-ui/icons/Group";

import { connect } from "react-redux";

import WarningIcon from "@material-ui/icons/Warning";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { TreeItem } from "@material-ui/lab";

import { ComponentsMenu } from "../../../../../../components";

import { componentServices } from "../../../../../../services/componentsservices";

import {
  getModuleComponentsAsyncAction,
  getModulesAsyncAction
} from "../../../../../../actions/moduleactions";

import { getTechnologyComponentsAsyncAction } from "../../../../../../actions/technologyactions";

var jwt = require("jsonwebtoken");

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    "&:focus > $content": {
      backgroundColor: "#F2FAFF",
      boxShadow: "1px 2px #00000029"
    }
  },
  content: {
    borderBottom: "1px solid #00000029",
    paddingLeft: "10px"
  },
  labelText: {
    padding: "24px 10px 10px 0px"
  },
  group: {
    margin: 0,
    backgroundColor: "#F7F7F7",
    labelText: { padding: "14px 10px 10px 0px" }
  },
  middletext: {
    padding: "14px 10px 10px 0px"
  },
  leaftext: {
    padding: "14px 10px 10px 0px",
    color: "#585858"
  },
  label: {
    maxWidth: "100%",
    marginLeft: "10px"
  },
  iconContainer: { display: "none" },
  alertcontent: {
    paddingLeft: "10px",
    boxShadow: "1px 1px #00000029",
    backgroundColor: "#E8555540"
  }
}));

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const user = jwt.decode(localStorage.getItem("user"));
  let level = props.item.Users.filter(item => item.Eid === user.id);
  if (level.length !== 0) {
    level = level[0].Level;
  }

  const handleChange = event => {
    event.stopPropagation();
    if (props.expandedPanel === "panel1") {
      componentServices
        .addModuleComponentExpertise(event.target.value, props.item.Cid)
        .then(data => {
          props.getModuleComponentsDispatch({
            mid: props.id,
            filter: { resource: "", expertise: "" },
            state: "update"
          });
          props.getModulesDispatch({ pid: props.pid, state: "update" });
        });
    } else if (props.expandedPanel === "panel2") {
      componentServices
        .addTechnologyComponentExpertise(event.target.value, props.item.Cid)
        .then(data => {
          props.getTechnologyComponentsDispatch({
            tid: props.id,
            filter: { resource: "", expertise: "" },
            state: "update"
          });
        });
    }
  };
  const {
    item,
    height,
    expandedNodes,
    getTechnologyComponentsDispatch,
    getModuleComponentsDispatch,
    getModulesDispatch,
    projectUsers,
    filter,
    ...other
  } = props;
  const labelTextClass =
    height === 0
      ? classes.labelText
      : height === 1
      ? classes.middletext
      : classes.leaftext;

  return (
    <TreeItem
      label={
        <Grid container alignContent="center">
          <Grid item xs={4}>
            <Grid
              container
              alignItems="center"
              style={{ borderLeft: height !== 0 && "2px solid #585858" }}
            >
              <Grid item xs={10}>
                <Typography variant="h6">
                  <Grid container>
                    {height > 1 &&
                      [...Array(height - 1)].map((e, i) => {
                        return (
                          <Grid
                            key={i}
                            item
                            style={{
                              borderRight: "2px solid #585858",
                              marginLeft: "30px"
                            }}
                          ></Grid>
                        );
                      })}
                    <Grid item className={labelTextClass}>
                      {height !== 0 && <strong>----|</strong>}
                      {props.leaf !== 0 ? (
                        <strong
                          style={{
                            paddingRight: "12px",
                            verticalAlign: "sub"
                          }}
                        >
                          {expandedNodes.includes(item.Cid + "") ? (
                            <MinusSquare />
                          ) : (
                            <PlusSquare />
                          )}
                        </strong>
                      ) : (
                        <strong
                          style={{ paddingRight: "12px", verticalAlign: "sub" }}
                        ></strong>
                      )}
                      {item.Cname}
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              {item.Alerts !== 0 &&
                height === 0 && [
                  <Grid item xs={1} key={1}>
                    <WarningIcon
                      style={{
                        fontSize: "20px",
                        verticalAlign: "middle",
                        color: "#F3572F"
                      }}
                    />
                  </Grid>,
                  <Grid item xs={1} key={2}>
                    <Typography variant="h6" style={{ color: "#F3572F" }}>
                      {item.Alerts}
                    </Typography>
                  </Grid>
                ]}
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              borderLeft: "1px solid  #8080804f",
              padding: "0px 10px 5px 15px"
            }}
          >
            <Grid container alignItems="center" justify="space-between">
              {props.leaf === 0 ? (
                [
                  <Typography variant="h6" style={{ fontSize: "16px" }} key={1}>
                    <GroupIcon
                      style={{ verticalAlign: "middle", marginRight: "5px" }}
                    />
                    {item.Users.filter(item => item.Level === 3).length}
                  </Typography>,
                  <Radio
                    key={2}
                    checked={level === 3}
                    onClick={handleChange}
                    value={3}
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "E" }}
                    size="small"
                  />
                ]
              ) : (
                <div>&nbsp;</div>
              )}
              <div>&nbsp;</div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              borderLeft: "1px solid  #8080804f",
              padding: "0px 10px 5px 15px"
            }}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid container alignItems="center" justify="space-between">
                {props.leaf === 0 ? (
                  [
                    <Typography
                      variant="h6"
                      style={{ fontSize: "16px" }}
                      key={1}
                    >
                      <GroupIcon
                        style={{ verticalAlign: "middle", marginRight: "5px" }}
                      />
                      {item.Users.filter(item => item.Level === 2).length}
                    </Typography>,
                    <Radio
                      key={2}
                      style={{ marginRight: "40%" }}
                      checked={level === 2}
                      onClick={handleChange}
                      value={2}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                  ]
                ) : (
                  <div>&nbsp;</div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              borderLeft: "1px solid  #8080804f",
              padding:
                props.leaf === 0 ? "0px 10px 5px 15px" : "0px 10px 10px 0px"
            }}
          >
            <Grid container justify="space-between" alignItems="center">
              {props.leaf === 0 ? (
                [
                  <Typography variant="h6" style={{ fontSize: "16px" }} key={1}>
                    <GroupIcon
                      style={{ verticalAlign: "middle", marginRight: "5px" }}
                    />
                    {projectUsers
                      ? projectUsers.length -
                        item.Users.length +
                        (level === 1 ? 1 : 0)
                      : 0}
                  </Typography>,
                  <Radio
                    key={2}
                    checked={level === 1}
                    onClick={handleChange}
                    value={1}
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "E" }}
                    size="small"
                  />
                ]
              ) : (
                <div>&nbsp;</div>
              )}
              <ComponentsMenu
                item={item}
                pid={props.pid}
                id={props.id}
                buttonText={
                  <MoreVertIcon
                    style={{
                      color: "#969696",
                      fontSize: "20px",
                      verticalAlign: "middle"
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      }
      classes={{
        root: classes.root,
        content:
          height === 0
            ? classes.content
            : item.Alerts === 0 || props.leaf !== 0
            ? classes.content
            : classes.alertcontent,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer
      }}
      {...other}
    />
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getModuleComponentsDispatch: e =>
      dispatch(getModuleComponentsAsyncAction(e)),
    getTechnologyComponentsDispatch: e =>
      dispatch(getTechnologyComponentsAsyncAction(e)),
    getModulesDispatch: e => dispatch(getModulesAsyncAction(e))
  };
};

const mapStateToProps = state => {
  return {
    projectUsers: state.resourceReducer.users,
    expandedPanel: state.sidebarReducer.expandedPanel
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StyledTreeItem);
