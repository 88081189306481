import initialState, { resourceConstants } from "../constants/constants.js";

const resourceReducer = (state = initialState.resourceReducer, action) => {
  const newState = { ...state };

  switch (action.type) {
    case resourceConstants.FETCH_PROJECT_USERS_REQUEST: {
      newState.users = [];
      newState.loading = true;
      newState.error = false;
      break;
    }
    case resourceConstants.FETCH_PROJECT_USERS_SUCCESS: {
      newState.users = action.data;
      newState.loading = false;
      newState.error = false;
      break;
    }
    case resourceConstants.FETCH_PROJECT_USERS_FAILURE: {
      newState.users = [];
      newState.loading = false;
      newState.error = true;
      break;
    }
    case resourceConstants.SET_SELECTED_RESOURCE: {
      newState.selectedResource = action.data;
      break;
    }
    case resourceConstants.RESET_RESOURCES_REDUCER: {
      return initialState.resourceReducer;
      break;
    }
    default: {
    }
  }
  return newState;
};

export default resourceReducer;
