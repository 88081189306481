import { projectConstants } from "../constants/constants.js";

export function getProjectsAsyncAction() {
  return { type: projectConstants.FETCH_PROJECTS };
}

export function setProjectsAction(data) {
  return { type: projectConstants.FETCH_PROJECTS_SUCCESS, data: data };
}

export function getProjectsFailureAction() {
  return { type: projectConstants.FETCH_PROJECTS_FAILURE };
}

export function getProjectsRequestAction() {
  return { type: projectConstants.FETCH_PROJECTS_REQUEST };
}
