import initialState, { alertConstants } from "../constants/constants.js";

const alertReducer = (state = initialState.alertReducer, action) => {
  const newState = { ...state };

  switch (action.type) {
    case alertConstants.SET_ALERT: {
      newState.alert = action.data;
      break;
    }
    default: {
    }
  }
  return newState;
};

export default alertReducer;
