import React from "react";
import { Button } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { connect } from "react-redux";

import { technologiesServices } from "../../services/technologiesservices";
import {
  getTechnologyComponentsAsyncAction,
  setTechnologiesAction,
  getTechnologiesRequestAction
} from "../../actions/technologyactions";
import { setAlertsAction } from "../../actions/alertactions";

import { EditPopOver, AddPopOver, ConfirmWindow } from "../../components";

function TechnologiesMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    setAnchorEl(null);
  };

  const deleteTechnology = event => {
    props.getTechnologiesRequestDispatch();
    technologiesServices.deleteTechnology(props.item.Tid, props.pid).then(
      data => {
        props.setTechnologiesDispatch(data);
      },
      err =>
        props.setAlertsDispatch({
          message: "Failed to delete technology",
          title: "Alert",
          open: true
        })
    );
    handleClose(event);
  };

  const editTechnology = formData => {
    props.getTechnologiesRequestDispatch();
    technologiesServices
      .editTechnology(formData, props.item.Tid, props.pid)
      .then(
        data => {
          props.setTechnologiesDispatch(data);
        },
        err =>
          props.setAlertsDispatch({
            message: "Failed to edit technology",
            title: "Alert",
            open: true
          })
      );
  };

  const addComponent = formData => {
    technologiesServices
      .addTechnologyComponent(formData, props.item.Tid, props.pid)
      .then(
        data =>
          props.getTechnologyComponentsDispatch({
            tid: props.item.Tid,
            filter: { resource: "", expertise: "" },
            state: "update"
          }),
        error =>
          props.setAlertsDispatch({
            message: "Failed to add technology component",
            title: "Alert",
            open: true
          })
      );
  };
  return (
    <div>
      <Button
        style={{ padding: 0, margin: 0, minWidth: 0 }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {props.buttonText}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ConfirmWindow
          buttonText="Delete Technology"
          handleSubmit={deleteTechnology}
          message="You want to delete the technology"
        />
        <AddPopOver
          buttonText="Add Component"
          title="Add Component"
          handleSubmit={addComponent}
        />
        <EditPopOver
          buttonText="Edit Technology"
          title="Edit Technology"
          formData={props.item.Tname}
          handleSubmit={editTechnology}
        />
      </Menu>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setTechnologiesDispatch: e => dispatch(setTechnologiesAction(e)),
    getTechnologyComponentsDispatch: e =>
      dispatch(getTechnologyComponentsAsyncAction(e)),
    getTechnologiesRequestDispatch: () =>
      dispatch(getTechnologiesRequestAction()),
    setAlertsDispatch: e => dispatch(setAlertsAction(e))
  };
};

export default connect(null, mapDispatchToProps)(TechnologiesMenu);
