import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

import { AddPopOver } from "./components";
import AddIcon from "@material-ui/icons/Add";
import { useMediaQuery } from "@material-ui/core";

import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import "./ProjectsToolbar.css";

var jwt = require("jsonwebtoken");

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    bottom: "20px",
    right: theme.spacing(3),
    backgroundColor: "black",
    color: "white",
    height: "50px;",
    "&:hover": {
      color: "black"
    }
  }
}));

const ProjectsToolbar = props => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const { className, alerts, ...rest } = props;
  const user = jwt.decode(localStorage.getItem("user"));

  const classes = useStyles();
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className="row">
        {alerts.Alerts !== 0 ? (
          [
            <Typography align="center" variant="h5" key={1}>
              <WarningIcon
                style={{
                  fontSize: "21px",
                  verticalAlign: "middle",
                  color: "#E85555"
                }}
              />
              TOTAL CRITICAL &nbsp;&nbsp;
            </Typography>,
            <div className="alert" key={2}>
              <Typography align="left" variant="h5" style={{ color: "white" }}>
                {alerts.Alerts}
              </Typography>
            </div>
          ]
        ) : (
          <Typography align="center" variant="h5">
            <CheckCircleIcon
              style={{
                fontSize: "21px",
                verticalAlign: "middle",
                color: "rgb(107, 189, 112)"
              }}
            />
            NO WARNINGS &nbsp;&nbsp;
          </Typography>
        )}

        <span className="spacer" />
        {user &&
          user.isadmin &&
          (isDesktop ? (
            <AddPopOver
              buttonText={
                <div>
                  Create new project{" "}
                  <AddIcon
                    style={{ fontSize: "21px", verticalAlign: "middle" }}
                  />
                </div>
              }
            />
          ) : (
            <AddPopOver
              className={classes.fab}
              buttonText={<AddIcon style={{ fontSize: "32px" }} />}
            />
          ))}
      </div>
    </div>
  );
};

ProjectsToolbar.propTypes = {
  className: PropTypes.string
};

export default ProjectsToolbar;
